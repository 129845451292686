import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import { APP_ROUTES } from '../../../App';
import { useHistory } from 'react-router-dom';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { constructAddress } from 'cw-demowallet-common/src/utils';
import { PURCHASE_ROUTES } from '../Purchase';
import { generatePath } from 'react-router';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import './PurchaseStart.scss';

function PurchaseStart({ purchase, isMarketplace }) {
	const reactHistory = useHistory();
	const { apiClientWithDialog, setAuthentication } = useApi();

	const handleSubmit = ({ loginOption }) => {
		if (loginOption === 'guest') {
			createGuestWalletAsync();
		} else if (loginOption === 'login') {
			isMarketplace ? reactHistory.push(`${APP_ROUTES.LOGIN}?purchaseId=${purchase.id}&isMarketplace=true`) : reactHistory.push(`${APP_ROUTES.LOGIN}?purchaseId=${purchase.id}`);
		} else if (loginOption === 'signup') {
			isMarketplace ? reactHistory.push(`${APP_ROUTES.SIGNUP}?purchaseId=${purchase.id}&isMarketplace=true`) : reactHistory.push(`${APP_ROUTES.SIGNUP}?purchaseId=${purchase.id}`);
		}
	};

	const createGuestWalletAsync = async () => {
		const response = await apiClientWithDialog({
			withCredentials: false,
			title: 'Create Guest Wallet',
			description:
				'<p>If the consumer prefers to <strong>continue as a Guest</strong>, a dedicated guest wallet will be created.</p>' +
				"<p>For this demonstration, we only store the bare minimum of data needed for the guest wallet, which is the consumer's <strong>country code</strong>. This is used " +
				'to determine the subsidiary under which the guest wallet should be created and to consider country-specific configurations, like limits.</p>' +
				'<p>Support of Guest wallets is optional and configurable, e.g. you can only allow one guest purchase per email address and require the consumer to signup for the second purchase.</p>',
			requestAnnotations: {
				countryCode: "The consumer's country code is used to determine the DemoWallet's subsidiary under which the wallet should be created and to consider country-specific configurations, like limits.",
			},
			responseAnnotations: {
				wallet: 'This attribute contains all necessary information about the created wallet.',
				subsidiaryId: 'This is the subsidiary to which the newly created wallet belongs. CoreWallet uses subsidiaries to allow multi-tenancy.',
				walletType: 'The type of wallet can be either Full, Guest or System.',
				accounts: 'A wallet can contain an arbitrary amount of accounts. In this case, one default account was created for the user.',
				currency: 'Wallet accounts can exist in any currency supported by the system.',
				accountBalance: 'The available balance is separated into directly available balance and balance which is already reserved for a specific usage.',
				auth: 'While the calls so far were made on behalf of the merchant or anonymously, this authentication must be used for all subsequent API calls on behalf of the guest consumer.',
			},
			type: 'POST',
			queryPath: API_ENDPOINTS.WALLET_REGISTER_GUEST,
			parameters: {
				countryCode: 'DE',
				billingAddress: constructAddress(JSON.parse(purchase.billingAddress), 'DE', 'BILLING'),
				shippingAddress: constructAddress(JSON.parse(purchase.shippingAddress), 'DE', 'SHIPPING'),
			},
		});
		guestCreationSucceeded(response);
	};

	const guestCreationSucceeded = (responseData) => {
		const loginData = responseData?.data;
		if (loginData?.walletId && loginData?.authentication?.apiKey) {
			setAuthentication({
				apiKey: loginData.authentication.apiKey,
				walletId: loginData.walletId,
			});
			const path = isMarketplace ? generatePath(PURCHASE_ROUTES.OPTIONS_MARKETPLACE, { purchaseId: purchase.id }) : generatePath(PURCHASE_ROUTES.OPTIONS, { purchaseId: purchase.id });
			reactHistory.push(path);
		}
	};

	return (
		<div className="purchase-start">
			<p>Please select how you want to pay:</p>
			<Formik onSubmit={handleSubmit} initialValues={{ loginOption: 'guest' }}>
				<Form>
					<div className="radio-box">
						<Field type="radio" name="loginOption" value="guest" />
						<div>
							<strong>Continue as Guest.</strong>
							<p>No DemoWallet account will be created. We will only store those details required to complete your purchase.</p>
						</div>
					</div>
					<div className="radio-box">
						<Field type="radio" name="loginOption" value="login" />
						<div>
							<strong>Login with your existing account.</strong>
							<p>Use this option if you already have a DemoWallet account. </p>
						</div>
					</div>
					<div className="radio-box">
						<Field type="radio" name="loginOption" value="signup" />
						<div>
							<strong>Create a new account.</strong>
							<p>
								This option is for you if you want to create a DemoWallet account and safely and conveniently store your user and payment information with DemoWallet. For your next purchase, you just need to login and confirm your
								purchase.
							</p>
						</div>
					</div>
					<Button variant="primary" type="submit">
						Continue
					</Button>
				</Form>
			</Formik>
		</div>
	);
}

export default PurchaseStart;
