import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Image } from 'react-bootstrap';
import './MockPayOption.scss';
import { PAYMENT_OPTION_CONSTANTS } from '../PaymentOptionConstants';
import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from 'yup';

const MockPayOption = forwardRef(({ isActive, triggerPayment, paymentMethod }, ref) => {
	const formRef = useRef();

	useImperativeHandle(ref, () => ({
		startPayment: async () => await formRef.current.submitForm(),
	}));

	const handleFormSubmit = (value) => {
		let paymentDetails = {
			selectedPaymentMethod: PAYMENT_OPTION_CONSTANTS.MOCK_PAY,
			selectedPaymentInstrumentId: value.selectedPaymentInstrumentId,
		};
		if (value.selectedPaymentInstrumentId === 'NEW') {
			paymentDetails = {
				...paymentDetails,
				todo: value.todo,
			};
		}
		triggerPayment(paymentDetails);
	};

	const paymentInstruments = paymentMethod?.paymentInstruments;

	const validationSchema = Yup.object().shape({
		selectedPaymentInstrumentId: Yup.string().required('Please choose one of the options'),
	});

	return (
		<div className="mock-pay-option">
			<p>
				<Image src={`${process.env.PUBLIC_URL}/assets/images/pm_mock.png`} className="logo" alt="Mock logo" title="Mock" />
			</p>
			<p>This is a Mock payment-method for testing.</p>
			{isActive && (
				<Formik innerRef={formRef} validationSchema={validationSchema} onSubmit={handleFormSubmit} initialValues={{ selectedPaymentInstrumentId: paymentInstruments?.length === 0 ? 'NEW' : '' }}>
					<div>
						{paymentInstruments && <p>Please select one of your stored mock accounts or add a new one:</p>}
						{paymentInstruments?.map((instrument, index) => (
							<div className="radio-box" key={index}>
								<Field type="radio" name="selectedPaymentInstrumentId" value={instrument.publicId} />
								<div>Mock account</div>
							</div>
						))}
						<div className="radio-box">
							<Field type="radio" name="selectedPaymentInstrumentId" value="NEW" />
							<div>Add a new Mock account</div>
						</div>
						<ErrorMessage name="selectedPaymentInstrumentId" component="div" className="invalid-feedback visible-error" />
					</div>
				</Formik>
			)}
		</div>
	);
});

export default MockPayOption;
