import { Field, Form, Formik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Input from 'cw-demowallet-common/src/components/input/Input';
import InputMask from 'react-input-mask';
import './ServiceAreaVoucher.scss';

export const SERVICE_AREA_VOUCHER_ROUTES = {
	SERVICE_AREA: '/servicearea',
};

const voucherSchema = Yup.object().shape({
	voucherCode: Yup.string()
		.required('Please enter your voucher code.')
		.matches(/^\w{4}-\w{4}-\w{4}-\w{5}$/, 'Please enter a valid voucher code.'),
});

function ServiceAreaVoucher({ user, wallet, updateUserAsync }) {
	const [selectedAccount, setSelectedAccount] = useState();
	const [isSubmitting, setIsSubmitting] = useState();

	const { apiClientWithDialog } = useApi();
	const dispatch = useDispatch();
	const history = useHistory();

	const handleTryVoucherAsync = async (formData) => {
		setIsSubmitting(true);
		try {
			const response = await apiClientWithDialog({
				title: 'Create Redeem Voucher',
				description: '<p>The <strong>Redeem Voucher Code</strong> API is used to redeem the <strong>available voucher code</strong> </p>',
				requestAnnotations: {
					voucherCode: 'The flow of payment, in this case Funding for a top up.',
					walletId: 'The ID of the wallet account which is to be topped up.',
				},
				responseAnnotations: {
					voucherInfo: {
						id: 'The ID of the voucher.',
						redeemerWalletId: 'wallet Id of the one who redeemed the voucher.',
						amount: 'The amount to be sent.',
						currency: 'The currency of the amount.',
						type: 'Invoice/P2P',
						code: 'the voucher code',
						description: 'description',
						creationDateTime: 'Date and time when the voucher was created.',
						validToDateTime: 'voucher validated to Date and time',
						redemptionDateTime: 'Date and time of redeem.',
					},
					invoiceInfo: {
						id: 'The ID of the created Invoice.',
						amount: 'The amount to be sent.',
						currency: 'The currency of the amount.',
						walletId: 'Customer Wallet ID',
						walletAccountId: 'Customer Wallet Account ID',
						invoiceStatus: 'status of invoice',
						vatRate: 'vat Rate',
						creationDateTime: 'Date and time when the invoice was created.',
					},
				},
				type: 'POST',
				queryPath: API_ENDPOINTS.REDEEM_VOUCHER,
				parameters: {
					voucherCode: formData.voucherCode,
					walletId: selectedAccount.walletId,
				},
			});

			const amount = response.data.voucherInfo.amount.amount;
			const currency = response.data.voucherInfo.amount.currency;

			dispatch(
				showToastMessage({
					title: 'Success Message',
					message: `You have successfully Redeemed the voucher with amount of  ${amount} ${currency}, please have a look at your balance in overview tab !`,
				}),
			);

			history.push(SERVICE_AREA_VOUCHER_ROUTES.SERVICE_AREA);
		} catch {
			dispatch(
				showToastMessage({
					title: 'Error Message',
					message: 'Failed to redeem voucher code, please contact support team.',
				}),
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		if (!selectedAccount && wallet?.accounts.length > 0) {
			setSelectedAccount(wallet.accounts[0]);
		}
	}, [wallet, selectedAccount]);

	return (
		<div className="voucher">
			<h2>Redeem With a voucher code</h2>
			<p className="help-text">Please specify the voucher code that you have been given.</p>
			<Formik initialValues={{ voucherCode: '' }} validationSchema={voucherSchema} onSubmit={handleTryVoucherAsync}>
				<Form>
					<Row>
						<Col md={2}>
							<strong>Voucher Code</strong>
						</Col>
						<Col md={7}>
							<Field name="voucherCode">
								{(fieldProps) => (
									<InputMask mask="****-****-****-*****" maskChar=" " disabled={isSubmitting} type="text" placeholder="XXXX-XXXX-XXXX-XXXXX" {...fieldProps.field}>
										{(inputProps) => <Input {...inputProps} />}
									</InputMask>
								)}
							</Field>
						</Col>
						<Col md={3}>
							<Button variant="primary" className="btn-sm" type="submit" disabled={isSubmitting}>
								Try Voucher
							</Button>
						</Col>
					</Row>
				</Form>
			</Formik>
		</div>
	);
}

export default ServiceAreaVoucher;
