import { Form, Formik } from 'formik';
import { Button, FormControl, FormGroup, FormLabel, Modal, Table } from 'react-bootstrap';
import Input from 'cw-demowallet-common/src/components/input/Input';
import { formatAmount, URIParams } from 'cw-demowallet-common/src/utils';
import { useEffect, useState } from 'react';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import PaymentOptions from '../../../components/paymentOptions/PaymentOptions';
import './ServiceAreaPayment.scss';
import { generatePath } from 'react-router';
import { SERVICE_AREA_ROUTES } from 'cw-demowallet-common/src/serviceAreaRoutes';
import { useHistory } from 'react-router-dom';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';
import CheckoutForm3dSecure from '../../../components/paymentOptions/checkoutForm3dSecure/CheckoutForm3dSecure';
import { postDynamicForm } from '../../../App';
import { ERROR_TYPES } from 'cw-demowallet-common/src/errorTypes';
import * as Yup from 'yup';

/*
 * This component provides functionality to initiate wallet payments of type Funding or Withdrawal.
 * As the required user interaction for these payment types is nearly identical, the small differences need to be provided through the following parameters:
 *
 * {
 * 	name: string
 * 	 Name of the payment (Top Up, Withdraw etc..)
 *
 * 	paymentType: string
 *      Used to create a suitable session for the given type of payment. you can provide only one of the types within the paymentTypes.js constants
 *
 * 	evaluateAmount: A function accepts, and returns a number
 * 	 A function to evaluate with selected amount to the payment session (Positive value for funding type and Negative value for the withdrawal type),
 *   it is recommended to use BigNumber.js for the payment amount manipulation (mathematically etc..) to avoid floating point precision loss
 *
 * 	title: string
 *      Page title to be displayed at the top
 *
 * 	paymentDirection: string
 *      Used in some APIs descriptions (annotations) to demonstrate the type of operation in the current Wallet Account. (possible values 'debit', 'credit')
 *
 *   minimumPaymentAmount: number
 * 	 Used to determine the payment minimum amount
 *
 *   modifyAmountValidation: A function accepts selected-wallet-account, and amount Yup number validator then returns modified Yup validator
 *    Used to set the maximum withdrawal amount by selected wallet account balance in the payment-withdraw type.
 *
 * 	startPayment: {
 * 		requestAnnotation: {
 * 			paymentType: string
 * 			 Payment-Type description in start-payment api call request annotations (to be displayed in the api call box)
 *     },
 *   },
 *
 *   preparePayment: {
 *    requestAnnotation: {
 *     paymentAmount: string
 *      Payment-Amount description in prepare-payment api call request annotations (to be displayed in the api call box)
 *
 *      paymentCurrency: string
 *        Payment-Currency description in prepare-payment api call request annotations (to be displayed in the api call box)
 *      },
 *    },
 *
 *    render: {
 * 	  paymentMethodSelectionText: string
 * 	   PaymentMethod-Selection-Text in the page's rendered HTML (in order to display the payment-selected-amount in your provided string, put '{amount}' and the code will replace it with the selected amount)
 *    },
 * }
 * */

function buildAmountValidator(configuration, wallet, selectedAccount) {
	let numberValidation = Yup.number().required('Please enter the amount');
	const minimumPaymentAmount = configuration.minimumPaymentAmount;
	if (minimumPaymentAmount || minimumPaymentAmount === 0) {
		numberValidation = numberValidation.min(minimumPaymentAmount, `The amount must be greater than ${minimumPaymentAmount}`);
	}

	if (configuration.modifyAmountValidation) {
		numberValidation = configuration.modifyAmountValidation(selectedAccount, numberValidation);
	}
	return numberValidation;
}

function ServiceAreaPayment({ wallet, updateUserAsync, configuration }) {
	const reactHistory = useHistory();
	const dispatch = useDispatch();
	const [amount, setAmount] = useState();
	const [selectedAccount, setSelectedAmount] = useState();
	const [isDisabled, setIsDisabled] = useState(false);
	const [paymentOptions, setPaymentOptions] = useState();
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [paymentSessionDetails, setPaymentSessionDetails] = useState();
	const [extendedError, setExtendedError] = useState('');
	const [paymentData, setPaymentData] = useState();
	const [checkoutForm3dsData, setCheckoutForm3dsData] = useState(null);
	const { apiClientWithDialog } = useApi();
	const accountSelected = (change) => setSelectedAmount(wallet.accounts[change.target.value]);
	const formValidationSchema = Yup.object().shape({
		amount: buildAmountValidator(configuration, wallet, selectedAccount),
	});

	const handleStartPaymentAsync = async (formData) => {
		if (!selectedAccount?.walletAccountId) {
			setExtendedError('please select an account');
			return;
		}
		setAmount(formData.amount);
		setIsDisabled(true);
		try {
			const response = await apiClientWithDialog({
				title: 'Create Payment Session',
				description:
					'<p>The <strong>Create Payment Session</strong> API is used to determine the <strong>available means of payment</strong> for a ' +
					configuration.name.toLowerCase() +
					' for the logged in consumer.</p>' +
					"<p>The available payment methods may differ depending on various parameters, like the consumer's country, the consumer's verification level, etc.</p>" +
					"<p>The corresponding payment method routing configuration can be edited in the DemoWallet's backoffice UI.</p>",
				requestAnnotations: {
					paymentType: configuration.startPayment.requestAnnotation.paymentType,
					walletAccountId: `The ID of the wallet account which is to be ${configuration.paymentDirection}ed.`,
				},
				responseAnnotations: {
					paymentMethods: `This list contains all the valid payment methods for the logged in consumer in the context of the current ${configuration.name.toLowerCase()}.`,
				},
				type: 'POST',
				queryPath: API_ENDPOINTS.CREATE_PAYMENT_SESSION,
				parameters: {
					paymentType: configuration.paymentType,
					walletAccountId: selectedAccount.walletAccountId,
				},
			});
			setPaymentOptions(response?.data);
			// setIsDisabled shouldn't be true yet
		} catch {
			// TODO deal with e.g. locked wallet account
			setIsDisabled(false);
		}
	};

	useEffect(() => {
		if (!selectedAccount && wallet?.accounts.length > 0) {
			setSelectedAmount(wallet.accounts[0]);
		}
	}, [wallet, selectedAccount]);

	const preparePaymentAsync = async (paymentSessionDetails) => {
		try {
			const response = await apiClientWithDialog({
				title: 'Create Payment',
				description: '<p>Based on the details gathered in the Payment Session, the <strong>Create Payment</strong> API now prepares a pending payment in the system.</p>',
				requestAnnotations: {
					description: 'An optional description which makes identifying the ' + configuration.name.toLowerCase() + ' easier for the consumer or employees.',
					paymentAmount: configuration.preparePayment.requestAnnotation.paymentAmount,
					paymentCurrency: configuration.preparePayment.requestAnnotation.paymentCurrency,
					paymentSessionDetailsJson: 'An object containing details from the selected payment method.',
					paymentSessionId: 'The ID of the payment session created in the last step.',
					walletAccountId: 'The ID of the wallet account to be ' + configuration.paymentDirection + 'ed.',
				},
				responseAnnotations: {
					id: 'This unique ID of the payment has to be used for subsequent API calls.',
					amount: 'The total payment amount.',
					paymentType: 'The type of this payment.',
					paymentStatus: 'The status of this payment. It starts with Created and will become Confirmed once the consumer has confirmed it in the next step.',
					feeAmount: 'An optional fee amount, depending on the system configuration.',
				},
				type: 'POST',
				queryPath: '/wallet/tx/payments',
				parameters: {
					description: configuration.name + ' from the DemoWallet',
					paymentAmount: configuration.evaluateAmount(amount),
					paymentCurrency: selectedAccount.currency,
					paymentSessionDetailsJson: paymentSessionDetails,
					paymentSessionId: paymentOptions.paymentSessionId,
					walletAccountId: selectedAccount.walletAccountId,
				},
			});
			setPaymentSessionDetails(paymentSessionDetails);
			setPaymentData(response?.data);
			setShowConfirmModal(true);
		} catch (e) {
			const error = e.response?.error;
			if (error?.error === ERROR_TYPES.LIMITS_VIOLATED) {
				const { limitsViolated: allLimitsViolated } = error;
				const [firstLimitViolated] = allLimitsViolated;
				const remainder = Math.max(firstLimitViolated.deltaToLimitValueAmount.amount, 0);
				setExtendedError(
					`Your ${configuration.name.toLowerCase()} limit is exceeded!${
						!remainder
							? ''
							: ` You can only ${configuration.name.toLowerCase().replace(' ', '-')} up to ${formatAmount({
									value: remainder,
									currency: firstLimitViolated.deltaToLimitValueAmount.currency,
							  })}!`
					}`,
				);
				handleCancelPayment();
			}
		}
	};

	const handleCancelPayment = () => {
		setPaymentOptions(null);
		setIsDisabled(false);
		setShowConfirmModal(false);
	};

	const onPaymentSuccess = () => {
		updateUserAsync();
		reactHistory.push(SERVICE_AREA_ROUTES.HOME);
	};

	const confirmPaymentAsync = async () => {
		if (!paymentData || !paymentSessionDetails || !paymentOptions) {
			setExtendedError('No payment method was selected');
			return;
		}
		try {
			const response = await apiClientWithDialog({
				title: 'Confirm Payment',
				description: `<p>After presenting the created payment to the user, which includes optional, system-calculated fees, the payment will now be confirmed.</p><p>This is also the point in time when the selected payment method will be ${
					configuration.paymentDirection === 'debit' ? 'credit' : 'debit'
				}ed.</p>`,
				requestAnnotations: {
					paymentSessionDetailsJson: 'An object containing details from the selected payment method. This is sent again because sensitive details like CVV are not stored.',
					paymentSessionId: 'The ID of the payment session created in the last step.',
				},
				responseAnnotations: {
					id: 'This unique ID of the payment has to be used for subsequent API calls.',
					amount: 'The total payment amount.',
					paymentType: 'The type of this payment.',
					paymentStatus: 'The status of this payment. It is now Approved and will become Completed once the payment instrument was successfully charged, or Failed if an error occurred.',
					feeAmount: 'An optional fee amount, depending on the system configuration.',
					error: 'An error has occurred, the flow cannot proceed and the UI will attempt to handle the error accordingly.',
					code: 'This is the error code the UI will respond to.',
				},
				type: 'POST',
				queryPath: generatePath(API_ENDPOINTS.CONFIRM_PAYMENT, { paymentId: paymentData.id }),
				parameters: {
					paymentSessionDetailsJson: paymentSessionDetails,
					paymentSessionId: paymentOptions.paymentSessionId,
				},
			});
			setShowConfirmModal(false);
			// logic based on ember
			if (response?.data) {
				// it's best if we do not make method-dependant decisions in here
				const { redirectRequired, paymentMethod, paymentSessionId } = response.data;
				if (redirectRequired) {
					const { redirectMethod, requestParameters, redirectUrl } = redirectRequired;
					if (redirectMethod === 'TopFrameGet') {
						document.location.href = `${redirectUrl}${requestParameters ? `?${URIParams(requestParameters)}` : ''}`;
					} else if (redirectMethod === 'TopFramePost') {
						postDynamicForm(redirectUrl, requestParameters);
					} else if (requestParameters?.type === 'threeDS2') {
						setCheckoutForm3dsData({
							paymentMethod,
							paymentSessionId,
							requestParameters: redirectRequired?.requestParameters,
							onSuccess: onPaymentSuccess,
							onError: (message) => {
								dispatch(showToastMessage({ title: 'Unknown 3DS exception', message }));
								setCheckoutForm3dsData(null);
							},
						});
					}
				} else {
					onPaymentSuccess();
				}
			} else {
				dispatch(showToastMessage({ title: 'Exception during Confirm', message: 'No data was retrieved from server' }));
			}
		} catch (_) {
			handleCancelPayment();
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'An exception was raised while confirming the payment.',
				}),
			);
		}
	};

	return (
		<div className="payment">
			<h2> {configuration.title} </h2>
			<p className="help-text">Please specify the amount you would like to {configuration.name.toLowerCase()}. Afterwards, you can select from the applicable payment methods.</p>
			<Formik initialValues={{ amount: '' }} validationSchema={formValidationSchema} onSubmit={handleStartPaymentAsync}>
				<>
					<Form className="payment-form" onChange={() => setExtendedError(null)}>
						<FormLabel>
							<strong>Amount</strong>
						</FormLabel>
						<Input className="amount-input" type="number" name="amount" disabled={isDisabled} />
						<FormGroup>
							<FormControl as="select" disabled={isDisabled} onChange={accountSelected}>
								{wallet.accounts.map((account, index) => (
									<option key={index} value={index}>
										{account.currency}
									</option>
								))}
							</FormControl>
						</FormGroup>
						<div className="d-grid gap-2">
							<Button variant="primary" className="btn-sm" type="submit" disabled={isDisabled}>
								Begin {configuration.name}
							</Button>
						</div>
					</Form>
					{extendedError && <div className="extended-error invalid-feedback">{extendedError}</div>}
				</>
			</Formik>
			{paymentOptions && (
				<>
					<p className="help-text">{configuration.render.paymentMethodSelectionText.replace('{amount}', `${formatAmount({ amount, currency: selectedAccount?.currency })}`)}</p>
					<PaymentOptions paymentMethods={paymentOptions?.paymentMethods} triggerPayment={preparePaymentAsync} cancelPayment={handleCancelPayment} />
				</>
			)}
			{paymentData?.amount && paymentData?.feeAmount && (
				<Modal show={showConfirmModal} onHide={() => {}}>
					<Modal.Header>
						<Modal.Title>Confirm {configuration.name}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Please confirm your {configuration.name.toLowerCase()}:</p>
						<Table>
							<thead>
								<tr>
									<th width="1%">#</th>
									<th>Description</th>
									<th width="1%" className="text-right">
										Amount
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1</td>
									<td>{configuration.name} Amount</td>
									<td className="text-right">{formatAmount(paymentData.amount)}</td>
								</tr>
								<tr>
									<td>2</td>
									<td>Fees</td>
									<td className="text-right">{formatAmount(paymentData.feeAmount)}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<th colSpan="2" className="text-right">
										Total
									</th>
									<th className="text-right">
										{formatAmount({
											amount: paymentData.feeAmount.amount + paymentData.amount.amount,
											currency: paymentData.amount.currency,
										})}
									</th>
								</tr>
							</tfoot>
						</Table>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-secondary" onClick={handleCancelPayment}>
							Cancel
						</Button>
						<Button variant="primary" onClick={confirmPaymentAsync}>
							Confirm
						</Button>
					</Modal.Footer>
				</Modal>
			)}
			{checkoutForm3dsData && <CheckoutForm3dSecure formData={checkoutForm3dsData} />}
		</div>
	);
}

export default ServiceAreaPayment;
