import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PURCHASE_ROUTES } from '../Purchase';
import React, { useCallback, useEffect, useState } from 'react';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { formatAmount, URIParams } from 'cw-demowallet-common/src/utils';
import { Button } from 'react-bootstrap';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import LoyaltyMembership from '../../../components/loyaltyMembership/LoyaltyMembership';
import PaymentOptions from '../../../components/paymentOptions/PaymentOptions';
import './PurchaseOptions.scss';
import CheckoutForm3dSecure from '../../../components/paymentOptions/checkoutForm3dSecure/CheckoutForm3dSecure';
import { postDynamicForm } from '../../../App';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';

function PurchaseOptions({ purchase, updatePurchase, isMarketplace }) {
	const reactHistory = useHistory();
	const dispatch = useDispatch();
	const [paymentOptions, setPaymentOptions] = useState();
	const [checkoutForm3dsData, setCheckoutForm3dsData] = useState(null);
	const { apiClientWithDialog, authentication } = useApi();
	const isEmoneyAvailable = paymentOptions?.emoneyAmount?.amount > 0;
	const isPaymentRequired = paymentOptions?.paymentAmount?.amount > 0;
	const isUsableLoyaltyCreditAvailable = paymentOptions?.consumerLoyaltyCreditsAmount?.amount > 0;

	if (!authentication && purchase?.id) {
		reactHistory.push(generatePath(isMarketplace ? PURCHASE_ROUTES.START_MARKETPLACE : PURCHASE_ROUTES.START_SHOP, { purchaseId: purchase.id }));
	}

	const reCreatePaymentSessionAsync = useCallback(async () => {
		if (authentication?.walletId && purchase?.id) {
			const marketplaceUrl = generatePath(API_ENDPOINTS.MARKETPLACE_PURCHASE_CREATE_SESSION, { purchaseId: purchase.id });
			const shopUrl = generatePath(API_ENDPOINTS.PURCHASE_CREATE_SESSION, { purchaseId: purchase.id });
			const response = await apiClientWithDialog({
				title: 'Create Payment Session',
				description:
					'<p>The <strong>Create Payment Session</strong> API is used to determine the <strong>payment amount</strong> and the <strong>available means of payment</strong> for the logged in ' +
					'consumer in the context of the given purchase.</p>' +
					'<p>Although not relevant for the guest wallet use case, the user may already have emoney balance on his/her wallet which could be deducted from the total purchase amount.</p>' +
					"<p>The available payment methods may differ depending on various parameters, like the consumer's country, the consumer's verification level, the purchase amount, " +
					'the merchant category code of the transaction, etc.</p>' +
					"<p>The corresponding payment method routing configuration can be edited in the DemoWallet's backoffice UI.</p>",
				requestAnnotations: {
					walletId: 'The wallet ID of the user.',
					purchaseId: 'The unique ID you received from the <strong>Create Purchase</strong> API call.',
					countryCode: '...',
				},
				responseAnnotations: {
					paymentAmount: 'This is the determined payment amount for which a payment method has to be charged. For guest purchases, this is equal to the total purchase amount.',
					paymentMethods: 'This list contains all the valid payment methods for the logged in consumer in the context of the current purchase.',
				},
				type: 'POST',
				queryPath: isMarketplace ? marketplaceUrl : shopUrl,
				parameters: {
					walletId: authentication.walletId,
					countryCode: 'DE',
				},
			});
			setPaymentOptions(response?.data);
		}
	}, [purchase, apiClientWithDialog, authentication, isMarketplace]);

	useEffect(() => reCreatePaymentSessionAsync(), [reCreatePaymentSessionAsync]);

	const prepareConfirmPurchaseAsync = async (paymentSessionDetails) => {
		const paymentSessionId = paymentOptions?.paymentMethodDetails?.paymentSessionId;
		if (isMarketplace) {
			await apiClientWithDialog({
				title: 'Prepare Confirm Marketplace Purchase',
				description:
					'<p>Once the consumer has made a decision which payment method to use and – depending on the payment method – entered payment instrument details like credit card data, the ' +
					'<strong>Prepare Confirm Purchase</strong> API call is made to make preliminary checks like duplicate checking and risk assessment.</p>',
				requestAnnotations: {
					paymentSessionDetails: "This attribute contains the user's payment method and payment instrument selection.",
				},
				responseAnnotations: {},
				type: 'POST',
				queryPath: generatePath(API_ENDPOINTS.MARKETPLACE_PURCHASE_PREPARE_CONFIRM, { purchaseId: paymentOptions.purchaseId }),
				parameters: {
					consumerWalletId: authentication.walletId,
					paymentSessionDetails: paymentSessionDetails,
					paymentSessionId,
				},
			});
		} else {
			await apiClientWithDialog({
				title: 'Prepare Confirm Purchase',
				description:
					'<p>Once the consumer has made a decision which payment method to use and – depending on the payment method – entered payment instrument details like credit card data, the ' +
					'<strong>Prepare Confirm Purchase</strong> API call is made to make preliminary checks like duplicate checking and risk assessment.</p>',
				requestAnnotations: {
					paymentSessionDetails: "This attribute contains the user's payment method and payment instrument selection.",
				},
				responseAnnotations: {},
				type: 'POST',
				queryPath: generatePath(API_ENDPOINTS.PURCHASE_PREPARE_CONFIRM, { purchaseId: paymentOptions.purchaseId }),
				parameters: {
					walletId: authentication.walletId,
					paymentSessionDetails: paymentSessionDetails,
					paymentSessionId,
				},
			});
		}
		await confirmPurchaseAsync(paymentSessionDetails, paymentSessionId);
	};

	const onPaymentSuccess = () => {
		updatePurchase();
		reactHistory.push(generatePath(isMarketplace ? PURCHASE_ROUTES.SUCCESS_MARKETPLACE : PURCHASE_ROUTES.SUCCESS, { purchaseId: paymentOptions.purchaseId }));
	};

	const confirmPurchaseAsync = async (paymentSessionDetails, paymentSessionId) => {
		const description = isMarketplace
			? '<p>The <strong>Confirm Marketplace Purchase</strong> API call finalizes the marketplace purchase confirmation and allows to provide one last review step for the user, e.g. before the purchase agreement is legally binding.'
			: '<p>The <strong>Confirm Purchase</strong> API call finalizes the purchase confirmation and allows to provide one last review step for the user, e.g. before the purchase agreement is legally binding.';
		const response = await apiClientWithDialog({
			title: 'Confirm Purchase',
			description: description,
			requestAnnotations: {
				paymentSessionDetails: "This attribute contains the user's payment method and payment instrument selection.",
			},
			responseAnnotations: {
				purchaseStatus: 'For purchases made with e.g. credit card, this status has now changed to <strong>Committed</strong>, indicating that all parties have agreed upon the purchase.',
			},
			type: 'POST',
			queryPath: isMarketplace ? generatePath(API_ENDPOINTS.MARKETPLACE_PURCHASE_CONFIRM, { purchaseId: paymentOptions.purchaseId }) : generatePath(API_ENDPOINTS.PURCHASE_CONFIRM, { purchaseId: paymentOptions.purchaseId }),
			parameters: {
				walletId: authentication.walletId,
				paymentSessionDetails: paymentSessionDetails,
				paymentSessionId,
			},
		});
		// logic based on ember
		if (response?.data) {
			// it's best if we do not make method-dependant decisions in here
			const { redirectRequired, purchaseStatus, paymentMethodDetails } = response.data;
			if (redirectRequired) {
				const { redirectMethod, requestParameters, redirectUrl } = redirectRequired;
				if (redirectMethod === 'TopFrameGet') {
					document.location.href = `${redirectUrl}${requestParameters ? `?${URIParams(requestParameters)}` : ''}`;
				} else if (redirectMethod === 'TopFramePost') {
					postDynamicForm(redirectUrl, requestParameters);
				} else {
					setCheckoutForm3dsData({
						paymentMethod: paymentMethodDetails.paymentMethods.find((paymentMethod) => paymentMethod.selected)?.name,
						paymentSessionId: paymentMethodDetails?.paymentSessionId,
						requestParameters,
						onSuccess: onPaymentSuccess,
						onError: (message) => {
							dispatch(showToastMessage({ title: 'Unknown 3DS exception', message }));
							setCheckoutForm3dsData(null);
						},
					});
				}
			} else if (purchaseStatus === 'Committed' || purchaseStatus === 'Completed') {
				onPaymentSuccess();
			} else {
				dispatch(showToastMessage({ title: 'Exception during Confirm', message: 'Redirect is not required but the state is not in "Committed" or "Completed".' }));
			}
		} else {
			dispatch(showToastMessage({ title: 'Exception during Confirm', message: 'No data was retrieved from server' }));
		}
	};

	return (
		<section className="purchase-options">
			{paymentOptions && (
				<>
					{isEmoneyAvailable && (
						<>
							<p>
								Your emoney is used to settle&nbsp;
								{isPaymentRequired && (
									<>
										<strong>{formatAmount(paymentOptions.emoneyAmount)}</strong> of
									</>
								)}
								&nbsp;the total purchase amount.
							</p>
						</>
					)}
					{isUsableLoyaltyCreditAvailable && (
						<>
							<p>
								Your usable loyalty credit balance is used to settle&nbsp;
								{isPaymentRequired && (
									<>
										<strong>{formatAmount(paymentOptions.consumerLoyaltyCreditsAmount)}</strong> of
									</>
								)}
								the total purchase amount.
							</p>
						</>
					)}
					<LoyaltyMembership
						purchaseId={paymentOptions.purchaseId}
						walletId={paymentOptions.walletId}
						consumerIssuableLoyaltyCreditsAmount={paymentOptions.consumerPotentialIssuableLoyaltyCreditsAmount}
						onCreatedMembership={reCreatePaymentSessionAsync}
					/>
					{!isPaymentRequired && (
						<Button variant="primary" onClick={() => prepareConfirmPurchaseAsync(null)}>
							Continue
						</Button>
					)}
					{isPaymentRequired && (
						<>
							<p>
								Please select your payment option to pay&nbsp;
								{isEmoneyAvailable && isUsableLoyaltyCreditAvailable && <>the remaining&nbsp;</>}
								<strong>{formatAmount(paymentOptions.paymentAmount)}</strong>.
							</p>
							<PaymentOptions triggerPayment={prepareConfirmPurchaseAsync} paymentMethods={paymentOptions.paymentMethodDetails?.paymentMethods} />
						</>
					)}
				</>
			)}
			{!paymentOptions && (
				<>
					Please wait a second while we determine your available payment options...
					<i className="fa fa-spin fa-spinner"></i>
				</>
			)}
			{checkoutForm3dsData && <CheckoutForm3dSecure formData={checkoutForm3dsData} />}
		</section>
	);
}

export default PurchaseOptions;
