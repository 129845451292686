import { Route } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import PurchaseStart from './purchaseStart/PurchaseStart';
import PurchaseOptions from './purchaseOptions/PurchaseOptions';
import PurchaseSuccess from './purchaseSuccess/PurchaseSuccess';
import PurchaseFailure from './purchaseFailure/PurchaseFailure';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { formatAmount } from 'cw-demowallet-common/src/utils';
import { generatePath } from 'react-router';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import './Purchase.scss';

export const PURCHASE_ROUTES = {
	START_SHOP: '/purchase/:purchaseId',
	START_MARKETPLACE: '/purchase/marketplace/:purchaseId',
	OPTIONS: '/purchase/:purchaseId/payment-options',
	OPTIONS_MARKETPLACE: '/purchase/marketplace/:purchaseId/payment-options',
	SUCCESS: '/purchase/:purchaseId/success',
	SUCCESS_MARKETPLACE: '/purchase/marketplace/:purchaseId/success',
	FAILURE: '/purchase/:purchaseId/failure',
	FAILURE_MARKETPLACE: '/purchase/marketplace/:purchaseId/failure',
};

function Purchase(props) {
	const { purchaseId } = props?.match?.params;
	const [purchaseData, setPurchaseData] = useState({});
	const dispatch = useDispatch();
	const isMarketplace = props?.isMarketplace;
	const { apiClient, authentication } = useApi();

	const getSellersInfo = useCallback(
		async (purchase) => {
			const sellerResponse = await apiClient({
				method: 'GET',
				url: `${API_ENDPOINTS.GET_SELLERS_INFO_PUBLIC}?${purchase.sellers.map((e) => `marketplaceSellerIds=${e.sellerId}`).join('&')}`,
			});
			const sellerResponseData = sellerResponse?.data;
			return sellerResponseData?.map((item) => {
				return {
					name: item.name,
					logoUrl: item.logoUrl,
					sellerId: item.id,
					amount: purchase.sellers.find((e) => e.sellerId === item.id).amount,
				};
			});
		},
		[apiClient],
	);

	const updatePurchaseAsync = useCallback(async () => {
		try {
			const marketplaceUrl = generatePath(authentication ? API_ENDPOINTS.MARKETPLACE_PURCHASE_GET : API_ENDPOINTS.MARKETPLACE_PURCHASE_PUBLIC_GET, { purchaseId });
			const shopUrl = generatePath(authentication ? API_ENDPOINTS.PURCHASE_GET : API_ENDPOINTS.PURCHASE_PUBLIC_GET, { purchaseId });
			const purchaseResponse = await apiClient({
				method: 'GET',
				url: isMarketplace ? marketplaceUrl : shopUrl,
			});
			let sellerInfo = null;
			const purchase = purchaseResponse?.data;
			if (isMarketplace) {
				sellerInfo = await getSellersInfo(purchase);
			}
			setPurchaseData({
				purchase,
				sellerInfo,
			});
		} catch {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'An exception was raised while calling the Service: Get Purchase',
				}),
			);
		}
	}, [dispatch, purchaseId, apiClient, authentication, isMarketplace, getSellersInfo]);

	useEffect(() => updatePurchaseAsync(), [updatePurchaseAsync]);

	return (
		<div className="purchase">
			<Container>
				<div className="header">
					<h1>DemoWallet</h1>
					<h4>
						built with
						<Image alt="CoreWallet logo" src={`${process.env.PUBLIC_URL}/assets/images/corewallet_headerlogo.svg`} />
					</h4>
				</div>
				<div className="purchase-overview">
					<Row className="justify-content-center">
						<Col md={12} lg={6}>
							<Card>
								<Card.Body>
									{isMarketplace && purchaseData?.sellerInfo && (
										<>
											<h4>Your purchase at</h4>
											{purchaseData?.sellerInfo.map((seller, index) => (
												<div key={index}>
													<Row>
														<Col xs={6}>
															<Image src={seller.logoUrl} alt="Seller logo" fluid />
															{seller.name}
														</Col>
														<Col xs={6}>
															<h4>Amount due</h4>
															<h3>{seller.amount && formatAmount(seller.amount)}</h3>
														</Col>
													</Row>
												</div>
											))}
											<hr />
											<div>
												<Row>
													<Col xs={6}>
														<h3>Total: </h3>
													</Col>
													<Col xs={6}>
														<h3>{formatAmount(purchaseData.purchase.amount)}</h3>
													</Col>
												</Row>
											</div>
										</>
									)}
									{!isMarketplace && (
										<>
											<Row>
												<Col xs={6}>
													<h4>Your purchase at</h4>
													<Image src={purchaseData?.purchase?.shopLogoUrl} alt="Shop logo" fluid />
													{purchaseData?.purchase?.shopName}
												</Col>
												<Col xs={6}>
													<h4>Amount due</h4>
													<h3>{purchaseData?.purchase?.amount && formatAmount(purchaseData.purchase.amount)}</h3>
												</Col>
											</Row>
										</>
									)}
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</div>
				<Route path={PURCHASE_ROUTES.START_SHOP} exact component={() => <PurchaseStart purchase={purchaseData?.purchase} isMarketplace={false} />} />
				<Route path={PURCHASE_ROUTES.START_MARKETPLACE} exact component={() => <PurchaseStart purchase={purchaseData?.purchase} isMarketplace={true} />} />
				<Route path={PURCHASE_ROUTES.OPTIONS} component={() => <PurchaseOptions purchase={purchaseData?.purchase} updatePurchase={updatePurchaseAsync} isMarketplace={false} />} />
				<Route path={PURCHASE_ROUTES.OPTIONS_MARKETPLACE} component={() => <PurchaseOptions purchase={purchaseData?.purchase} updatePurchase={updatePurchaseAsync} isMarketplace={true} />} />
				<Route path={PURCHASE_ROUTES.SUCCESS} component={() => <PurchaseSuccess purchase={purchaseData?.purchase} isMarketplace={false} />} />
				<Route path={PURCHASE_ROUTES.SUCCESS_MARKETPLACE} component={() => <PurchaseSuccess purchase={purchaseData?.purchase} isMarketplace={true} />} />
				<Route path={PURCHASE_ROUTES.FAILURE} component={() => <PurchaseFailure purchase={purchaseData?.purchase} isMarketplace={false} />} />
				<Route path={PURCHASE_ROUTES.FAILURE_MARKETPLACE} component={() => <PurchaseFailure purchase={purchaseData?.purchase} isMarketplace={true} />} />
			</Container>
		</div>
	);
}

export default Purchase;
