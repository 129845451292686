import React, { useCallback, useEffect, useState } from 'react';
import AddressModal from './addressmodal/AddressModal';
import { generatePath } from 'react-router';
import { Button, Card } from 'react-bootstrap';
import './ProfileAddress.scss';
import './addressmodal/AddressModal.scss';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';

export const ADDRESS_ROUTES = {
	EDIT: '/servicearea/profile/address/editaddress/:id',
	ADD: '/servicearea/profile/address/addaddress',
};

function ProfileAddress({ user }) {
	const { apiClient } = useApi();
	const dispatch = useDispatch();
	const reactHistory = useHistory();
	const [currentAddress, setCurrentAddress] = useState();
	const [addresses, setAddresses] = useState([]);

	const updateAddressAsync = useCallback(async () => {
		try {
			const addressResponse = await apiClient({
				method: 'GET',
				url: generatePath(API_ENDPOINTS.USER_ADDRESSES, { userId: user.id }),
			});
			setAddresses(addressResponse?.data);
		} catch {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'An exception was raised while calling the Service: Get Addresses',
				}),
			);
		}
	}, [user, dispatch, apiClient]);

	const deleteAddress = async () => {
		try {
			await apiClient({
				method: 'DELETE',
				url: generatePath(API_ENDPOINTS.USER_ADDRESS, { userId: user.id, addressId: currentAddress.id }),
			});
			updateAddressAsync();
		} catch {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'An exception was raised while calling the Service: Delete Address',
				}),
			);
		}
	};

	useEffect(() => updateAddressAsync(), [updateAddressAsync]);

	const displayAddress = (address) => {
		const addressName = `${address.displayName}, ${address.firstName}, ${address.lastName}`;
		const addressDetails = `, ${address.addressLine1}, ${address.addressLine2}, ${address.zip}, ${address.city}, ${address.country}`;
		const addressType = ` (${address.standard ? 'Primary, ' : ''}${address.verified ? 'Verified, ' : ''}${address.type})`;
		return (
			<>
				<strong> {addressName} </strong> {addressDetails}
				{addressType}
			</>
		);
	};

	return (
		<div className="profile-address">
			<Card>
				<Card.Header>Saved addresses</Card.Header>
				<Card.Body>
					<div className="radio-box">
						{addresses.map((address, index) => (
							<div key={index}>
								<input type="radio" name="address" value={address} onChange={() => setCurrentAddress(address)} checked={address.id === currentAddress?.id} />
								{displayAddress(address)}
							</div>
						))}
					</div>
					<div>
						<Button variant="primary" className="address-button" type="submit" onClick={() => reactHistory.push(ADDRESS_ROUTES.ADD)}>
							Add Address
						</Button>
						<Button variant="outline-dark" className="address-button" type="submit" onClick={deleteAddress} disabled={!currentAddress}>
							Delete the selected Address
						</Button>
						<Button variant="outline-dark" className="address-button" type="button" onClick={() => reactHistory.push(generatePath(ADDRESS_ROUTES.EDIT, { id: currentAddress?.id }))} disabled={!currentAddress}>
							Edit Address
						</Button>
					</div>
				</Card.Body>
			</Card>
			<Switch>
				<Route path={ADDRESS_ROUTES.EDIT} component={(props) => <AddressModal user={user} updateAddressAsync={updateAddressAsync} {...props} />} />
				<Route path={ADDRESS_ROUTES.ADD} component={() => <AddressModal user={user} updateAddressAsync={updateAddressAsync} />} />
			</Switch>
		</div>
	);
}

export default ProfileAddress;
