import React, { forwardRef, useRef } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import PaypalOption from './paypal/PaypalOption';
import SepaOption from './sepa/SepaOption';
import CreditCardOption from './creditcard/CreditCardOption';
import './PaymentOptions.scss';
import AdyenCreditCardOption from './adyencreditcard/AdyenCreditCardOption';
import { PAYMENT_OPTION_CONSTANTS } from './PaymentOptionConstants';
import MockPayOption from './mockpay/MockPayOption';
import DemoPaypalOption from './demopaypal/DemoPaypalOption';

function PaymentOptions({ triggerPayment, paymentMethods, cancelPayment }) {
	const selectedOptionRef = useRef();
	const validationSchema = Yup.object().shape({ selectedPaymentMethod: Yup.string().required('Please choose one of the payment methods') });

	const IndividualPaymentOption = forwardRef(({ type, ...others }, selectedRef) => {
		const ref = others.isActive ? selectedRef : null;
		switch (type) {
			case PAYMENT_OPTION_CONSTANTS.PAYPAL:
				return <PaypalOption {...others} ref={ref} />;
			case PAYMENT_OPTION_CONSTANTS.SEPA:
				return <SepaOption {...others} ref={ref} />;
			case PAYMENT_OPTION_CONSTANTS.CREDIT_CARD:
				return <CreditCardOption {...others} ref={ref} />;
			case PAYMENT_OPTION_CONSTANTS.ADYEN_CREDIT_CARD:
				return <AdyenCreditCardOption {...others} ref={ref} />;
			case PAYMENT_OPTION_CONSTANTS.MOCK_PAY:
				return <MockPayOption {...others} ref={ref} />;
			case PAYMENT_OPTION_CONSTANTS.DEMO_PAYPAL:
				return <DemoPaypalOption {...others} ref={ref} />;
			default:
				return <div>UNKNOWN PAYMENT OPTION</div>;
		}
	});

	return (
		<div className="payment-options">
			<Formik onSubmit={() => selectedOptionRef.current.startPayment()} validationSchema={validationSchema} initialValues={{ selectedPaymentMethod: '' }}>
				{(formik) => {
					return (
						<Form>
							{paymentMethods?.map((paymentMethod, index) => (
								<div className="radio-box" key={index}>
									<Field type="radio" name="selectedPaymentMethod" value={paymentMethod.name} />
									<IndividualPaymentOption ref={selectedOptionRef} type={paymentMethod.name} triggerPayment={triggerPayment} paymentMethod={paymentMethod} isActive={paymentMethod.name === formik.values['selectedPaymentMethod']} />
								</div>
							))}
							<ErrorMessage name="selectedPaymentMethod" component="div" className="invalid-feedback visible-error" />
							{cancelPayment && (
								<>
									<button type="button" className="text-link" onClick={cancelPayment}>
										Back to last step
									</button>
									&nbsp;or&nbsp;
								</>
							)}
							<Button type="submit" variant="primary" disabled={!formik.isValid || formik.isSubmitting}>
								Continue
							</Button>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
}

export default PaymentOptions;
