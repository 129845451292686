import React, { useCallback, useEffect } from 'react';
import './CheckoutForm3dSecure.scss';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { Modal } from 'react-bootstrap';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { generatePath } from 'react-router';

function CheckoutForm3dSecure({ formData }) {
	const { onError, onSuccess, requestParameters, paymentMethod, paymentSessionId } = formData;
	const { apiClient } = useApi();

	const createAdyen3ds2Form = useCallback(() => {
		if (!paymentSessionId) {
			onError('Was not able to extract paymentSessionId');
			return;
		}
		if (!requestParameters?.adyenClientKey) {
			onError('Was not able to extract adyen client key');
			return;
		}

		const checkout = new AdyenCheckout({
			locale: 'en_US',
			environment: 'test',
			clientKey: requestParameters.adyenClientKey,
			onAdditionalDetails: async (result) => {
				try {
					const validateResponse = await apiClient({
						method: 'POST',
						data: {
							...result.data,
							paymentSessionId,
						},
						url: generatePath(API_ENDPOINTS.ADYEN_VALIDATE_3DS2),
					});
					const status = validateResponse?.data?.status;
					if (status === 'Committed' || status === 'Success') {
						onSuccess(validateResponse?.data);
					} else {
						onError('3DSecure is not in "Committed" or "Success" state');
					}
				} catch (error) {
					onError(`3DSecure cannot be validated ${error?.message}`);
				}
			},
		});

		checkout
			.createFromAction(
				{
					paymentMethodType: requestParameters.scheme,
					token: requestParameters.token,
					paymentData: requestParameters.paymentData,
					type: requestParameters.type,
					subtype: requestParameters.subType,
				},
				{
					challengeWindowSize: '05',
				},
			)
			.mount('#checkout-form-3d-secure-container');
	}, [apiClient, onError, onSuccess, paymentSessionId, requestParameters]);

	useEffect(() => {
		// up until here 3ds secure form is not method-dependant
		// for handling future payment methods and their 3ds forms please add a new case here
		switch (paymentMethod) {
			case 'adyenCreditCard':
				createAdyen3ds2Form();
				break;
			default:
				onError('An unknown payment method is requesting 3ds which is not supported');
				break;
		}
	}, [createAdyen3ds2Form, onError, paymentMethod]);

	return (
		<Modal show={true} onHide={() => {}} size={'lg'} className="checkout-form-3d-secure">
			<Modal.Header>
				<Modal.Title>3DSecure Checkout Form</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div id="checkout-form-3d-secure-container"></div>
			</Modal.Body>
		</Modal>
	);
}

export default CheckoutForm3dSecure;
