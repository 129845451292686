import { Link } from 'react-router-dom';
import { PURCHASE_ROUTES } from '../Purchase';
import { generatePath } from 'react-router';
import React, { useEffect, useState } from 'react';
import './PurchaseFailure.scss';

function PurchaseFailure({ purchase, isMarketplace }) {
	const [returnUrl, setReturnUrl] = useState();

	useEffect(() => {
		if (isMarketplace) {
			setReturnUrl(purchase && purchase?.marketplaceRedirectUrl && purchase.marketplaceRedirectUrl.replace('{{purchaseId}}', purchase.id).replace('{{purchaseStatus}}', 'Failure'));
		} else {
			setReturnUrl(purchase && purchase?.shopReturnUrl && purchase.shopReturnUrl.replace('{{purchaseId}}', purchase.id).replace('{{purchaseStatus}}', 'Failure'));
		}
	}, [purchase, isMarketplace]);

	return (
		<section className="purchase-failure">
			<i className="fa fa-exclamation-triangle big" aria-hidden="true"></i>
			<p>This didn't work!</p>
			<p>
				{purchase?.id && (
					<>
						<Link
							to={generatePath(PURCHASE_ROUTES.OPTIONS, {
								purchaseId: purchase.id,
							})}
						>
							Click here to retry
						</Link>{' '}
						or <a href={returnUrl}>return to the shop</a>
					</>
				)}
				.
			</p>
		</section>
	);
}

export default PurchaseFailure;
