import { Link, NavLink, Route, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import Profile from './profile/Profile';
import ServiceAreaHome from './home/ServiceAreaHome';
import ServiceAreaP2P from './p2p/ServiceAreaP2P';
import ServiceAreaRedeem from './redeem/ServiceAreaRedeem';
import ServiceAreaVerifyEmail from './verifyEmail/ServiceAreaVerifyEmail';
import { Container, Nav, Navbar } from 'react-bootstrap';
import './ServiceArea.scss';
import { APP_ROUTES } from '../../App';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { setRedirectUrlAfterLogin, showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import ServiceAreaVoucher from './voucher/ServiceAreaVoucher';
import { SERVICE_AREA_ROUTES } from 'cw-demowallet-common/src/serviceAreaRoutes';
import { useLocation } from 'react-router-dom';
import ServiceAreaPayment from './payment/ServiceAreaPayment';
import { PAYMENT_TYPES } from 'cw-demowallet-common/src/paymentTypes';
import BigNumber from 'bignumber.js';
import { formatAmount } from 'cw-demowallet-common/src/utils';

function ServiceArea() {
	const reactHistory = useHistory();
	const dispatch = useDispatch();
	const [user, setUser] = useState();
	const [wallet, setWallet] = useState();
	const { apiClient, authentication, setAuthentication } = useApi();
	const currentLocation = useLocation();

	useEffect(() => {
		if (authentication === undefined) {
			dispatch(setRedirectUrlAfterLogin(generatePath(currentLocation.pathname)));
			reactHistory.push(APP_ROUTES.LOGIN);
		}
	}, [authentication, reactHistory, dispatch, currentLocation]);

	const updateUserAsync = useCallback(async () => {
		if (authentication?.walletId) {
			try {
				const walletResponse = await apiClient({
					method: 'GET',
					url: generatePath(API_ENDPOINTS.WALLET_GET, { walletId: authentication.walletId }),
				});
				const wallet = walletResponse?.data;
				setWallet(wallet);
				const userResponse = await apiClient({
					method: 'GET',
					url: generatePath(API_ENDPOINTS.USER_GET, { userId: wallet?.owner?.id }),
				});
				setUser(userResponse?.data);
			} catch {
				dispatch(
					showToastMessage({
						title: 'Unexpected exception',
						message: 'An exception was raised while calling the Service: Get Wallet/User',
					}),
				);
			}
		}
	}, [authentication, dispatch, apiClient]);

	useEffect(() => updateUserAsync(), [updateUserAsync]);

	const triggerLogout = () => {
		setAuthentication(null);
		reactHistory.push(APP_ROUTES.LOGIN);
	};

	const topUpPaymentConfiguration = {
		name: 'Top Up',
		paymentType: PAYMENT_TYPES.FUNDING,
		evaluateAmount: (amount) => new BigNumber(amount),
		title: 'Top up your account balance',
		paymentDirection: 'credit',
		minimumPaymentAmount: 0,
		startPayment: {
			requestAnnotation: {
				paymentType: 'The payment flow in this case is funding for a top-up.',
			},
		},
		preparePayment: {
			requestAnnotation: {
				paymentAmount: 'The amount to be topped up.',
				paymentCurrency: 'The currency to be topped up.',
			},
		},
		render: {
			paymentMethodSelectionText: 'Please select a payment method in order to top up your account with {amount}.',
		},
	};

	const withdrawalPaymentConfiguration = {
		name: 'Withdrawal',
		paymentType: PAYMENT_TYPES.WITHDRAWAL,
		evaluateAmount: (amount) => new BigNumber(amount).negated(),
		title: 'Withdraw From Wallet',
		paymentDirection: 'debit',
		minimumPaymentAmount: 0,
		modifyAmountValidation: (selectedAccount, validator) => {
			const walletAccount = wallet?.accounts.find((account) => account.walletAccountId === selectedAccount?.walletAccountId);
			if (walletAccount) {
				const currentBalance = walletAccount.accountBalance.balance.amount;
				const maxWithdrawalAmountMessage = currentBalance ? `You can withdraw up to ${formatAmount(walletAccount.accountBalance.balance)}` : `There is no available balance to make a withdrawal.`;
				validator = validator.max(currentBalance, maxWithdrawalAmountMessage);
			}
			return validator;
		},
		startPayment: {
			requestAnnotation: {
				paymentType: 'The payment flow in this case is withdrawal for a withdrawal',
			},
		},
		preparePayment: {
			requestAnnotation: {
				paymentAmount: 'The amount to be withdrawn.',
				paymentCurrency: 'The currency to be withdrawn from.',
			},
		},
		render: {
			paymentMethodSelectionText: 'Please select a payment method in order to withdraw {amount} from your account.',
		},
	};

	return (
		<div className="service-area">
			<Navbar bg="light">
				<Container>
					<Link to={SERVICE_AREA_ROUTES.HOME} className="navbar-brand" color="light">
						DemoWallet
					</Link>
					<Nav className="main-navigation">
						<NavLink to={SERVICE_AREA_ROUTES.HOME} exact className="nav-link">
							Overview
						</NavLink>
						<NavLink to={SERVICE_AREA_ROUTES.TOP_UP} className="nav-link">
							Top up
						</NavLink>
						<NavLink to={SERVICE_AREA_ROUTES.WITHDRAW} className="nav-link">
							Withdraw
						</NavLink>
						<NavLink to={SERVICE_AREA_ROUTES.P2P} className="nav-link">
							P2P
						</NavLink>
						<NavLink to={SERVICE_AREA_ROUTES.PROFILE} className="nav-link">
							Profile
						</NavLink>
					</Nav>
					<Nav>
						<Nav.Link onClick={triggerLogout} className="nav-link">
							Logout
						</Nav.Link>
					</Nav>
				</Container>
			</Navbar>
			{user && (
				<Container>
					<Route path={SERVICE_AREA_ROUTES.HOME} exact component={() => <ServiceAreaHome user={user} wallet={wallet} updateUserAsync={updateUserAsync} />} />
					<Route path={SERVICE_AREA_ROUTES.TOP_UP} component={() => <ServiceAreaPayment wallet={wallet} updateUserAsync={updateUserAsync} configuration={topUpPaymentConfiguration} />} />
					<Route path={SERVICE_AREA_ROUTES.P2P} component={() => <ServiceAreaP2P user={user} wallet={wallet} />} />
					<Route path={SERVICE_AREA_ROUTES.REDEEM} component={(props) => <ServiceAreaRedeem user={user} wallet={wallet} updateUserAsync={updateUserAsync} {...props} />} />
					<Route path={SERVICE_AREA_ROUTES.VOUCHER} component={() => <ServiceAreaVoucher user={user} wallet={wallet} updateUserAsync={updateUserAsync} />} />
					<Route path={SERVICE_AREA_ROUTES.VERIFY_EMAIL} component={() => <ServiceAreaVerifyEmail user={user} />} />
					<Route path={SERVICE_AREA_ROUTES.PROFILE} component={() => <Profile user={user} wallet={wallet} updateUserAsync={updateUserAsync} />} />
					<Route path={SERVICE_AREA_ROUTES.WITHDRAW} component={() => <ServiceAreaPayment wallet={wallet} updateUserAsync={updateUserAsync} configuration={withdrawalPaymentConfiguration} />} />
				</Container>
			)}
			{!user && (
				<Container>
					<h2>Loading user info...</h2>
				</Container>
			)}
		</div>
	);
}

export default ServiceArea;
