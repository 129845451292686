import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import './DocumentModal.scss';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { generatePath } from 'react-router';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';

const FILE_TOO_LARGE_ERROR = 'The selected file is too large. Please select a smaller file!';
const DOCUMENT_LIMIT_REACHED = 'The document limit is reached, please delete an old id document before adding a new one.';
const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred.';
const SUCCESS_MESSAGE = 'File uploaded successfully.';
const PASSPORT_DISPLAY_MESSAGE = 'Please upload an ID selfie showing your face and the front of your passport with your photo.\\n" +\nJPG, PNG or PDF files, maximum file size 10 MB.';
const UTILITY_DISPLAY_MESSAGE = 'Please upload a copy of a utility bill not older than three months as a proof of residency.\\n +\nJPG, PNG or PDF files, maximum file size 10 MB.';
const ERROR_MESSAGE = 'Unexpected exception';

function DocumentModal({ hideUploadModal, show, user, documentType, updateDocumentAsync }) {
	const { apiClient } = useApi();
	const dispatch = useDispatch();
	const [file, setFile] = useState();

	const handleFiles = (e) => {
		setFile(e.target.files[0]);
	};

	const uploadFileAsync = async () => {
		try {
			var formData = new FormData();
			formData.append('file', file);
			formData.append('qualifier', documentType);
			await apiClient({
				method: 'POST',
				data: formData,
				url: generatePath(API_ENDPOINTS.USER_DOCUMENT, { userId: user.id }),
			});
			hideUploadModal();
			updateDocumentAsync();
			dispatch(
				showToastMessage({
					title: 'Success',
					message: SUCCESS_MESSAGE,
				}),
			);
		} catch (error) {
			hideUploadModal();
			if (isFileTooLargeError(error)) {
				dispatch(
					showToastMessage({
						title: ERROR_MESSAGE,
						message: FILE_TOO_LARGE_ERROR,
					}),
				);
			} else if (isDocumentLimitReached(error)) {
				dispatch(
					showToastMessage({
						title: ERROR_MESSAGE,
						message: DOCUMENT_LIMIT_REACHED,
					}),
				);
			} else {
				dispatch(
					showToastMessage({
						title: ERROR_MESSAGE,
						message: UNEXPECTED_ERROR_MESSAGE,
					}),
				);
			}
		}
	};

	const isFileTooLargeError = (error) => {
		// If rejected by webserver, http status code is 413
		if (error.status === 413) {
			return true;
		}
		// If rejected by backend, standard error payload is returned
		if (error.responseJSON) {
			error = error.responseJSON.error;
			if (error && error.code === 'InternalError' && error.message && error.message.indexOf('SizeLimitExceededException') > -1) {
				return true;
			}
		}
		return false;
	};

	const isDocumentLimitReached = (error) => {
		// If rejected by backend for reaching the document limit.
		if (error.responseJSON) {
			error = error.responseJSON.error;
			if (error && error.error === 'DocumentLimitReached') {
				return true;
			}
		}
		return false;
	};

	return (
		<Modal show={show} className="upload-documents">
			<Modal.Header>
				<strong>{documentType === 'PassportCopy' ? 'Upload passport document' : 'Upload utility document'}</strong>
			</Modal.Header>
			<Modal.Body>
				<Formik
					initialValues={{
						file: '',
					}}
					onSubmit={uploadFileAsync}
				>
					<Form>
						<div>
							<div>{documentType === 'PassportCopy' ? PASSPORT_DISPLAY_MESSAGE : UTILITY_DISPLAY_MESSAGE}</div>
							<Row>
								<Col xs={8}>
									<input id="file" name="file" type="file" onChange={(event) => handleFiles(event)} className="form-control" />
								</Col>
								<Col xs={4}>
									<Button variant="primary" className="button-ops" type="submit">
										Upload
									</Button>
								</Col>
							</Row>
							<hr />
							<div className="button-container">
								<Button variant="outline-dark" className="button-ops" type="button" onClick={hideUploadModal}>
									Close
								</Button>
							</div>
						</div>
					</Form>
				</Formik>
			</Modal.Body>
		</Modal>
	);
}

export default DocumentModal;
