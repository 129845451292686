import { formatAmount, formatTimestamp } from '../../../utils';

function AccountStatementPayment({ accountItem }) {
	return (
		<>
			<td>{formatTimestamp(accountItem.creationDateTime)}</td>
			<td>{formatAmount(accountItem.amount)}</td>
			<td>{accountItem.itemStatus}</td>
			<td>Payment: {accountItem.description}</td>
			<td>
				<a target="_new" href={`https://demowallet.trimplement.com/admin/payments/${accountItem.transactionOwner?.id}`}>
					<i className="fa fa-external-link"></i>
				</a>
			</td>
		</>
	);
}

export default AccountStatementPayment;
