import { Toast, ToastBody, ToastHeader } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { closeToastMessage } from '../../redux/actions';
import './ToastMessage.scss';

function ToastMessage() {
	const dispatch = useDispatch();
	const toastMessage = useSelector((state) => state?.toastMessage);

	const closeToast = () => dispatch(closeToastMessage());

	return (
		<Toast onClose={closeToast} show={toastMessage?.show} delay={5000} autohide className="toast-message">
			<ToastHeader>
				<strong className="mr-auto">{toastMessage?.title}</strong>
			</ToastHeader>
			<ToastBody>{toastMessage?.message}</ToastBody>
		</Toast>
	);
}

export default ToastMessage;
