import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { PROFILE_ROUTES } from 'cw-demowallet-common/src/profileRoutes';

function ProfileHome() {
	const reactHistory = useHistory();
	// copied behaviour from ember project
	useEffect(() => reactHistory.push(PROFILE_ROUTES.PERSONAL_DETAILS), [reactHistory]);

	return <></>;
}

export default ProfileHome;
