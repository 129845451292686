import { formatAmount, formatTimestamp } from '../../../utils';
import { useCallback, useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';
import { API_ENDPOINTS } from '../../../endpoints';

function AccountStatementP2PIncoming({ accountItem }) {
	const [p2pTransaction, setP2pTransaction] = useState();
	const dispatch = useDispatch();
	const { apiClient } = useApi();

	const getP2PTransactionAsync = useCallback(async () => {
		try {
			const response = await apiClient({
				method: 'GET',
				url: generatePath(API_ENDPOINTS.GET_TRANSACTION_P2P, { transactionId: accountItem.transactionOwner?.id }),
			});
			setP2pTransaction(response?.data);
		} catch {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'An exception was raised while calling the Service: Get P2P',
				}),
			);
		}
	}, [accountItem, dispatch, apiClient]);

	useEffect(() => getP2PTransactionAsync(), [getP2PTransactionAsync]);

	return (
		<>
			<td>{formatTimestamp(accountItem.creationDateTime)}</td>
			<td>{formatAmount(accountItem.amount)}</td>
			<td>{accountItem.itemStatus}</td>
			<td>
				{p2pTransaction && (
					<p>
						P2P transfer from {p2pTransaction.sourceUserName} ({p2pTransaction.sourceUserIdentifier}): {accountItem.description}
					</p>
				)}
				{!p2pTransaction && <i className="fa fa-spin fa-spinner"></i>}
			</td>
			<td>
				<a target="_new" href={`https://demowallet.trimplement.com/admin/p2p-transactions/${accountItem.transactionOwner.id}`}>
					<i className="fa fa-external-link"></i>
				</a>
			</td>
		</>
	);
}

export default AccountStatementP2PIncoming;
