import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Card, Image, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import cardValidator from 'card-validator';
import { Field, Formik } from 'formik';
import './AdyenCreditCardOption.scss';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { PAYMENT_OPTION_CONSTANTS } from '../PaymentOptionConstants';

const AdyenCreditCardOption = forwardRef(({ isActive, triggerPayment, paymentMethod }, ref) => {
	const formRef = useRef();
	const [newChecked, setNewChecked] = useState(false);

	useImperativeHandle(ref, () => ({
		startPayment: formRef.current.submitForm,
	}));

	const handleOnCheckedByInstrumentId = (e) => {
		setNewChecked(false);
		formRef.current.values.selectedPaymentInstrumentId = e.target.value;
		formRef.current.values.encryptedSecurityCode = '';
		const adyenClientKey = paymentMethod.metadata.adyenClientKey;

		const checkout = new AdyenCheckout({
			locale: 'en_US',
			environment: 'test',
			onChange: onDataChangeByInstrumentId,
			clientKey: adyenClientKey,
		});

		const storedPaymentMethod = e.target.value;
		const mountTag = `#${e.target.value}`;

		checkout.create('securedfields', storedPaymentMethod).mount(mountTag);
	};

	const onDataChangeByInstrumentId = ({ data, isValid }) => {
		if (isValid) {
			formRef.current.values.encryptedSecurityCode = data.paymentMethod.encryptedSecurityCode;
			formRef.current.values.browserInfo = data.browserInfo;
		}
	};

	const handleOnCheckedByCard = (e) => {
		setNewChecked(true);
		formRef.current.values.selectedPaymentInstrumentId = 'NONE';
		formRef.current.values.encryptedSecurityCode = '';

		const checkout = new AdyenCheckout({
			locale: 'en_US',
			environment: 'test',
			onChange: onDataChangeByCard,
			clientKey: paymentMethod.metadata.adyenClientKey,
		});

		checkout
			.create('card', {
				enableStoreDetails: true,
				hasHolderName: true,
				holderNameRequired: true,
				brands: ['mc', 'visa', 'amex', 'maestro', 'maestrouk'],
			})
			.mount('#customCard-container');
	};

	const onDataChangeByCard = ({ data, isValid }) => {
		if (isValid && formRef.current) {
			formRef.current.values.encryptedCardNumber = data?.paymentMethod?.encryptedCardNumber;
			formRef.current.values.encryptedExpiryMonth = data?.paymentMethod?.encryptedExpiryMonth;
			formRef.current.values.encryptedExpiryYear = data?.paymentMethod?.encryptedExpiryYear;
			formRef.current.values.encryptedSecurityCode = data?.paymentMethod?.encryptedSecurityCode;
			formRef.current.values.owner = data?.paymentMethod?.holderName;
			formRef.current.values.storePaymentMethod = !!data?.storePaymentMethod;
			formRef.current.values.browserInfo = data?.browserInfo;
		}
	};

	const handleFormSubmit = (value) => {
		let paymentDetails = {
			selectedPaymentMethod: PAYMENT_OPTION_CONSTANTS.ADYEN_CREDIT_CARD,
			selectedPaymentInstrumentId: value.selectedPaymentInstrumentId,
			encryptedSecurityCode: value.encryptedSecurityCode,
			browserInfo: value.browserInfo,
			origin: window.location.origin,
		};
		if (value.selectedPaymentInstrumentId === 'NONE') {
			paymentDetails = {
				...paymentDetails,
				owner: value.owner,
				storePaymentMethod: value.storePaymentMethod,
				encryptedCardNumber: value.encryptedCardNumber,
				encryptedExpiryMonth: value.encryptedExpiryMonth,
				encryptedExpiryYear: value.encryptedExpiryYear,
			};
		}
		triggerPayment(paymentDetails);
	};

	const paymentInstruments = paymentMethod?.paymentInstruments;

	const validationSchema = Yup.object().shape({
		selectedPaymentInstrumentId: Yup.string().required('Please choose one of the options'),
		encryptedSecurityCode: Yup.string().required('Please enter a valid CVV.'),
		encryptedExpiryMonth: Yup.string().when('selectedPaymentInstrumentId', (instrument, schema) => (instrument !== 'NONE' ? schema : schema.required('Please enter an expiry date/month.'))),
		encryptedExpiryYear: Yup.string().when('selectedPaymentInstrumentId', (instrument, schema) => (instrument !== 'NONE' ? schema : schema.required('Please enter an expiry date/year.'))),
		encryptedCardNumber: Yup.string().when('selectedPaymentInstrumentId', (instrument, schema) => (instrument !== 'NONE' ? schema : schema.required('Please enter a credit card number.'))),
		owner: Yup.string().when('selectedPaymentInstrumentId', (instrument, schema) =>
			instrument !== 'NONE' ? schema : schema.required('Please enter a name.').test('card-name-internal-id', 'Please enter a valid name.', (cardName) => cardValidator.cardholderName(cardName).isValid),
		),
	});

	return (
		<div className="adyen-credit-card-option">
			<p>
				<Image alt="Credit Card logo" title="Credit Card" className="logo" src={`${process.env.PUBLIC_URL}/assets/images/pm_adyencreditcard.jpg`} />
			</p>
			<p>
				We can charge your <strong>credit card</strong>. Your data will be securely stored.
			</p>

			{isActive && (
				<Formik
					innerRef={formRef}
					validationSchema={validationSchema}
					onSubmit={handleFormSubmit}
					initialValues={{
						selectedPaymentInstrumentId: 'NONE',
						encryptedSecurityCode: '',
						encryptedCardNumber: '',
						encryptedExpiryMonth: '',
						encryptedExpiryYear: '',
						owner: '',
						storePaymentMethod: false,
					}}
				>
					{(formik) => (
						<div>
							{paymentInstruments && <p>Please select one of your stored credit cards or add a new one:</p>}
							{paymentInstruments?.map((instrument, index) => (
								<div className="radio-box" key={index} onChange={handleOnCheckedByInstrumentId}>
									<Field type="radio" name="selectedPaymentInstrumentId" value={instrument.publicId} />
									<div>
										{instrument.publicFields.cardScheme} {instrument.publicFields.displayValue} (exp. {instrument.publicFields.validToMonth}/{instrument.publicFields.validToYear})
										<div id={instrument.publicId} className={`${instrument.publicId === formik.values['selectedPaymentInstrumentId'] && !newChecked ? 'credit-card-stored' : 'credit-card-stored-hidden'}`}>
											<Row className="p-2 d-flex">
												<label>CVV/CVC:</label>
												<span data-cse="encryptedSecurityCode" className="adyen-checkout__input adyen-checkout__input--small adyen-checkout__card__cvc__input" />
											</Row>
										</div>
									</div>
								</div>
							))}
							<div className="radio-box">
								<Field type="radio" checked={newChecked} onChange={handleOnCheckedByCard} name="selectedPaymentInstrumentId" value="NONE" />
								<div>Add a new card</div>
							</div>
							<Card className={`${newChecked ? 'credit-card-box' : 'credit-card-box-hidden'}`}>
								<Row>
									<div id="customCard-container" />
									<div id="card-3ds-container"></div>
								</Row>
							</Card>
						</div>
					)}
				</Formik>
			)}
		</div>
	);
});

export default AdyenCreditCardOption;
