import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { Form } from 'react-bootstrap';

function Input(props) {
	const [field, meta] = useField(props);
	const { id, label, name, className, type = 'text', ...rest } = props;
	const { touched, error } = meta;

	return (
		<Form.Group controlId={id} className={className}>
			{label && (
				<Form.Label>
					<strong>{label}</strong>
				</Form.Label>
			)}
			<Form.Control name={name} type={type} isInvalid={touched && error} {...field} {...rest} />
			<ErrorMessage name={name} component={Form.Control.Feedback} type="invalid" />
		</Form.Group>
	);
}

export default Input;
