import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import Input from 'cw-demowallet-common/src/components/input/Input';
import './AddEmail.scss';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { generatePath } from 'react-router';
import React from 'react';

function AddEmail({ hideEmailModal, user, updateEmailDisplay }) {
	const handleAddAddressAsync = async (formData) => {
		await apiClientWithDialog({
			title: 'Add email address',
			description: '<p>The <strong>Add email address</strong> API is used to add a new email address for an existing user.',
			requestAnnotations: {
				email: 'New email address to be added.',
				primary: 'Denotes if this email address is to be set as primary address. This is set to false by default since only a verified email address can be set as primary.',
				subscribeToNewsletter: 'Denotes if this email address is to be subscribed to the newsletter.',
			},
			responseAnnotations: {
				id: 'The ID of the newly added email address.',
				creationDateTime: 'Date and time when the email address was added.',
				email: 'Returns the current email address which was added successfully.',
				displayEmail: 'Returns the display email address.',
				verified: 'Denotes whether or not the email address was verified, it is set to false for the newly added address.',
				standard: 'Denotes if this email address is the primary address.',
				status: 'Returns the status of the email address.',
				newsletterSubscriptionStatus: 'Returns the status of the newsletter subscription.',
			},
			type: 'POST',
			queryPath: generatePath(API_ENDPOINTS.EMAIL_ADDRESS, { userId: user.id }),
			parameters: {
				email: formData.email,
				primary: 'false',
				subscribeToNewsletter: '',
			},
		});
		updateEmailDisplay();
		hideEmailModal();
	};

	const { apiClientWithDialog } = useApi();

	return (
		<Modal show={true} className="add-email">
			<Modal.Body>
				<Formik
					initialValues={{
						email: '',
					}}
					onSubmit={handleAddAddressAsync}
				>
					<Form>
						<div>
							<Row>
								<Col xs={12}>
									<Input label="Email Address" name="email" type="text" />
								</Col>
								<Col xs={12}>
									<div className="radio-box">
										<div>
											<Field type="radio" name="subscribeToNewsletter" className="radio-btn" checked={false} />
											Subscribe to news letter
										</div>
									</div>
								</Col>
							</Row>
							<div className="button-container">
								<Button variant="primary" className="button-ops" type="submit">
									Add a new email address
								</Button>
								<Button variant="outline-dark" className="button-ops" type="button" onClick={hideEmailModal}>
									Close
								</Button>
							</div>
						</div>
					</Form>
				</Formik>
			</Modal.Body>
		</Modal>
	);
}

export default AddEmail;
