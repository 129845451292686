import { APP_ROUTES } from '../../App';
import { Link } from 'react-router-dom';
import './Home.scss';

function Home() {
	return (
		<div className="index">
			<h1>Welcome to DemoWallet!</h1>
			<p>
				DemoWallet is an emoney wallet similar to e.g. PayPal or ClickandBuy, built with <a href="https://trimplement.com/corewallet">CoreWallet</a>.
			</p>
			<p>It uses CoreWallet extension points to make up an actual product, featuring simple customer self service functionality, a purchase flow, and corresponding back office enhancements.</p>
			<p>
				You can either <Link to={APP_ROUTES.SIGNUP}>sign up</Link> or <Link to={APP_ROUTES.LOGIN}>login</Link>, or visit the <a href="https://demowallet.trimplement.com/shop">example single shop</a> or&nbsp;
				<a href="https://demowallet.trimplement.com/shop/marketplace">example marketplace</a> to initiate a purchase.
			</p>
			<p>
				You can also browse the <a href="https://corewallet.trimplement.com">CoreWallet documentation</a> for more details.
			</p>
		</div>
	);
}

export default Home;
