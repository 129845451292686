import { Card, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../../App';
import './PasswordRecovery.scss';

function PasswordRecovery() {
	const queryParams = useLocation().search;
	const status = new URLSearchParams(queryParams).get('status');
	return (
		<section className="password-recovery">
			<Container>
				<Card>
					<Card.Body>
						<h3>Password Recovery</h3>
						{status === 'Failed' && (
							<>
								<i className="fa fa-exclamation-triangle"></i>
								<p>
									Password recovery mail could not be sent.
									<br />
									Please try again. Please click <Link to={APP_ROUTES.LOGIN}>here</Link> to go back to Login.
								</p>
							</>
						)}
						{status === 'Success' && (
							<>
								<i className="fa fa-check"></i>
								<p>
									Password recovery mail sent successfully. Please check your mail box. Please click <Link to={APP_ROUTES.LOGIN}>here</Link> to go back to Login.
								</p>
							</>
						)}
					</Card.Body>
				</Card>
			</Container>
		</section>
	);
}

export default PasswordRecovery;
