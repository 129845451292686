import VerificationStatus from 'cw-demowallet-common/src/components/verificationStatus/VerificationStatus';
import { formatAmount } from 'cw-demowallet-common/src/utils';
import AccountStatement from 'cw-demowallet-common/src/components/accountStatement/AccountStatement';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import React from 'react';

export const SERVICE_AREA_HOME_ROUTES = {
	VOUCHER: '/servicearea/voucher',
};

function ServiceAreaHome({ user, wallet, updateUserAsync }) {
	const userName = user.firstName || user.primaryEmail;
	const history = useHistory();

	const printAvailableBalance = (accounts) => {
		return accounts
			.map((account) => {
				const part1 = formatAmount(account?.accountBalance?.balance);
				if (account?.accountBalance.reservedBalance?.amount) {
					return part1 + ` (reserved ${formatAmount(account?.accountBalance?.reservedBalance)})`;
				}
				return part1;
			})
			.join(', ');
	};

	return (
		<div className="service-area-home">
			<h2>Hello, {userName}</h2>
			<VerificationStatus verificationLevel={user.verificationLevel} userId={user.id} email={user.primaryEmail} />
			<Card className="account-statement">
				<Card.Body>
					<Row>
						<Col>
							<h3>Your available balance is {printAvailableBalance(wallet.accounts)} .</h3>
						</Col>
						<Col>
							<Button className="float-right" variant="info" onClick={() => history.push(SERVICE_AREA_HOME_ROUTES.VOUCHER)}>
								Redeem Voucher
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>

			<AccountStatement wallet={wallet} />
		</div>
	);
}

export default ServiceAreaHome;
