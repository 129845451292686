import { useCallback, useEffect, useState } from 'react';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { generatePath } from 'react-router';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { APP_ROUTES } from '../../../App';
import { formatAmount } from 'cw-demowallet-common/src/utils';

function ServiceAreaRedeem(props) {
	const dispatch = useDispatch();
	const [transactionData, setTransactionData] = useState();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const reactHistory = useHistory();
	const transactionId = props.match?.params?.transactionId;
	const walletId = props.wallet?.id;
	const { apiClient, apiClientWithDialog } = useApi();

	const loadRedeemInfoAsync = useCallback(async () => {
		const response = await apiClient({
			method: 'GET',
			url: generatePath(API_ENDPOINTS.GET_TRANSACTION_P2P, { transactionId: transactionId }),
		});
		const transactionData = response.data;
		setTransactionData(transactionData);
		if (transactionData.p2PTransaction.transactionStatus !== 'Confirmed') {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'This P2P transaction does not exist or was already redeemed!',
				}),
			);
			reactHistory.push(APP_ROUTES.SERVICE_AREA);
		} else if (transactionData.sourceWalletId === walletId) {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'You cannot redeem your own P2P transaction!',
				}),
			);
			reactHistory.push(APP_ROUTES.SERVICE_AREA);
		} else {
			setTransactionData(transactionData);
		}
	}, [walletId, transactionId, apiClient, dispatch, reactHistory]);

	useEffect(() => loadRedeemInfoAsync(), [loadRedeemInfoAsync]);
	const acceptP2p = async () => {
		setIsSubmitting(true);
		try {
			await apiClientWithDialog({
				title: 'Accept P2P Transaction',
				description: '<p>As long as the P2P transaction is neither expired, cancelled, or already accepted or declined, the recipient can accept.</p>',
				requestAnnotations: {
					// no request body
				},
				responseAnnotations: {
					targetWalletId: 'Note that the target wallet ID is now filled.',
					transactionStatus: 'The transaction status has now changed to Cancelled.',
				},
				type: 'POST',
				queryPath: generatePath(API_ENDPOINTS.ACCEPT_WALLET_P2P_TRANSFER, { p2pTxId: transactionId }),
				parameters: {},
			});
			dispatch(
				showToastMessage({
					title: 'Success',
					message: `${formatAmount(transactionData?.p2PTransaction?.amount, transactionData?.p2PTransaction?.currency)}
                for '${transactionData?.p2PTransaction?.description}' have been credited to your account.`,
				}),
			);
			reactHistory.push(APP_ROUTES.SERVICE_AREA);
		} finally {
			setIsSubmitting(false);
		}
	};

	const declineP2p = async () => {
		setIsSubmitting(true);
		try {
			await apiClientWithDialog({
				title: 'Reject P2P Transaction',
				description: '<p>As long as the P2P transaction is neither expired, cancelled, or already accepted or decline, the recipient can decline.</p>',
				requestAnnotations: {
					// no request body
				},
				responseAnnotations: {
					// no response body
				},
				type: 'POST',
				queryPath: generatePath(API_ENDPOINTS.REJECT_WALLET_P2P_TRANSFER, { p2pTxId: transactionId }),
				parameters: {},
			});
			dispatch(
				showToastMessage({
					title: 'Success',
					message: `You have declined the transfer of
                ${formatAmount(transactionData?.p2PTransaction?.amount, transactionData?.p2PTransaction?.currency)}
                for '${transactionData?.p2PTransaction?.description}' have been credited to your account.`,
				}),
			);
			reactHistory.push(APP_ROUTES.SERVICE_AREA);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<section className="redeem">
			<div className="container">
				{transactionData?.p2PTransaction?.amount && (
					<>
						<h2>
							{transactionData?.sourceUserName} wants to send you {formatAmount(transactionData?.p2PTransaction?.amount)}{' '}
						</h2>
						<p>
							{transactionData?.sourceUserName} ({transactionData?.sourceUserIdentifier}) has reserved {formatAmount(transactionData?.p2PTransaction?.amount)} for "{transactionData?.p2PTransaction?.description}", ready to be securely
							transferred using DemoWallet. Do you want to accept the transfer?
						</p>
						<div className="buttons">
							<button type="button" className="btn btn-primary" onClick={acceptP2p} disabled={isSubmitting}>
								Accept
							</button>
							<button type="button" className="btn btn-default" onClick={declineP2p} disabled={isSubmitting}>
								Decline
							</button>
						</div>
					</>
				)}
			</div>
		</section>
	);
}

export default ServiceAreaRedeem;
