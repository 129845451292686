import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import Input from 'cw-demowallet-common/src/components/input/Input';
import './VerifyEmail.scss';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { generatePath } from 'react-router';
import React from 'react';

function VerifyEmail({ hideEmailVerifyModal, user, selectedEmail }) {
	const handleEmailVerificationAsync = async (formData) => {
		await apiClientWithDialog({
			title: 'Finish email address verification',
			description: '<p>The <strong>Finish email address verification</strong> API is used to complete the verification process of the selected email address.',
			requestAnnotations: {
				emailAddress: 'Email address for which the verification process is triggered.',
				verificationToken: 'Token sent to the registered email address for verification confirmation.',
			},
			responseAnnotations: {},
			type: 'POST',
			queryPath: generatePath(API_ENDPOINTS.COMPLETE_VERIFY_EMAIL_ADDRESS, {
				userId: user.id,
			}),
			parameters: {
				emailAddress: selectedEmail.email,
				verificationToken: formData.token,
			},
		});
		hideEmailVerifyModal();
	};

	const { apiClientWithDialog } = useApi();

	return (
		<Modal show={true}>
			<Modal.Body>
				<div className="verify-email">
					<Formik
						initialValues={{
							token: '',
						}}
						onSubmit={handleEmailVerificationAsync}
					>
						<Form>
							<div>
								<Row>
									<Col xs={12}>
										<Input label="Please enter the token to verify" name="token" type="text" />
									</Col>
								</Row>
								<div className="button-container">
									<Button variant="primary" className="ops-button" type="submit">
										Verify email address
									</Button>
									<Button variant="outline-dark" className="ops-button" type="button" onClick={hideEmailVerifyModal}>
										Close
									</Button>
								</div>
							</div>
						</Form>
					</Formik>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default VerifyEmail;
