import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { PROFILE_ROUTES } from '../../profileRoutes';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { useDispatch } from 'react-redux';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { generatePath } from 'react-router';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';

function VerificationStatus({ verificationLevel, email, userId }) {
	const dispatch = useDispatch();

	const { apiClientWithDialog } = useApi();

	const resendVerificationEmailAsync = async () => {
		try {
			await apiClientWithDialog({
				title: 'Start Email Verification',
				description: '<p>The <strong>Start Email Verification</strong> API is used to start email verification.',
				requestAnnotations: {
					emailAddress: 'email address to be verified.',
				},
				responseAnnotations: {},
				type: 'POST',
				queryPath: generatePath(API_ENDPOINTS.EMAIL_START_VERIFICATION, {
					userId,
				}),
				parameters: {
					emailAddress: email,
				},
			});
			dispatch(
				showToastMessage({
					title: 'Verification email',
					message: 'Verification email sent successfully. Please check your mailbox!',
				}),
			);
		} catch {
			dispatch(
				showToastMessage({
					title: 'Verification email',
					message: 'Verification email cannot be sent. Please try again or contact support if the issue persists.',
				}),
			);
		}
	};

	return (
		<h4>
			{verificationLevel === 'EMONEY_UNVERIFIED' && (
				<Alert variant="warning">
					Please confirm your email address for verification. Click{' '}
					<button className="text-link" onClick={resendVerificationEmailAsync}>
						here
					</button>{' '}
					to resend the verification email.
				</Alert>
			)}
			{verificationLevel === 'EMONEY_BASIC' && (
				<Alert variant="warning">
					Please <Link to={PROFILE_ROUTES.UPLOAD_DOCUMENTS}>upload</Link> your documents for verification.
				</Alert>
			)}
		</h4>
	);
}

export default VerificationStatus;
