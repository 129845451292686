import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { apiCallFailed, apiCallRunning, apiCallSucceeded, changeApiCallTab, setAuthenticationError, showApiCall, showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'cw-demowallet-wallet-ui-react/src/App';
import { useLocation } from 'react-router-dom';
import { ERROR_TYPES } from './errorTypes';

export function useApi() {
	const reactHistory = useHistory();
	const currentLocation = useLocation();
	const [cookies, setCookie, removeCookie] = useCookies();
	const authentication = cookies?.authentication;
	const dispatch = useDispatch();
	const [submitDialogMethods, setSubmitDialogMethods] = useState({
		resolve: null,
		reject: null,
	});
	const [continueDialogMethods, setContinueDialogMethods] = useState({
		resolve: null,
		reject: null,
	});

	useSelector((state) => {
		if (submitDialogMethods.resolve && state?.apiCall?.running) {
			submitDialogMethods.resolve();
		}
	});

	useSelector((state) => {
		if (continueDialogMethods.resolve && !state?.apiCall.show) {
			continueDialogMethods.resolve();
			setContinueDialogMethods({ resolve: null, reject: null });
		}
	});

	const setAuthentication = (value) => (value ? setCookie('authentication', value, { path: '/' }) : removeCookie('authentication', { path: '/' }));

	const apiClient = useCallback(
		({ withCredentials = true, ...props }) => {
			const axios = Axios.create({ baseURL: '/api/v2' });

			axios.interceptors.response.use(
				(response) => response,
				(error) => {
					const errorType = error.response.data.error.error;
					const httpStatusCode = error.response.status;

					if ((httpStatusCode === 400 || httpStatusCode === 401) && errorType === ERROR_TYPES.AUTHENTICATION) {
						dispatch(setAuthenticationError(true));
						reactHistory.push(APP_ROUTES.LOGIN);
					}

					return Promise.reject(error);
				},
			);

			if (authentication?.apiKey && withCredentials) {
				axios.defaults.withCredentials = true;
				axios.defaults.headers['Api-Key'] = authentication.apiKey;
			} else {
				axios.defaults.withCredentials = false;
				delete axios.defaults.headers['Api-Key'];
			}

			return axios(props).then((response) => response?.data);
		},
		[authentication, dispatch, reactHistory, currentLocation],
	);

	const apiClientWithDialog = useCallback(
		async ({ title, description, requestAnnotations, responseAnnotations, type, queryPath, parameters, withCredentials = true }) => {
			dispatch(
				showApiCall({
					title,
					description,
					requestAnnotations,
					responseAnnotations,
					type,
					queryPath,
					parameters,
				}),
			);
			await new Promise((resolve, reject) => setSubmitDialogMethods({ resolve, reject }));
			// real api call
			let apiResponse = undefined;
			try {
				apiResponse = await apiClient({
					method: type,
					url: queryPath,
					data: parameters,
					withCredentials,
				});
				dispatch(apiCallSucceeded(apiResponse));
			} catch (error) {
				dispatch(apiCallFailed(error.response?.data || error));
				throw error;
			} finally {
				dispatch(apiCallRunning(false));
				dispatch(changeApiCallTab('output'));
				await new Promise((resolve, reject) => setContinueDialogMethods({ resolve, reject }));
			}
			return apiResponse;
		},
		[apiClient, dispatch],
	);

	return {
		authentication,
		apiClient,
		apiClientWithDialog,
		setAuthentication,
	};
}
