import { NavLink, Route } from 'react-router-dom';
import Switch from 'react-bootstrap/Switch';
import React from 'react';
import ProfileHome from './home/ProfileHome';
import PersonalDetails from './personalDetails/PersonalDetails';
import ProfilePassword from './password/ProfilePassword';
import ProfileEmail from './email/ProfileEmail';
import UploadDocuments from './uploadDocuments/UploadDocuments';
import ProfileAddress from './address/ProfileAddress';
import './Profile.scss';
import { PROFILE_ROUTES } from 'cw-demowallet-common/src/profileRoutes';

function Profile({ user, wallet, updateUserAsync }) {
	return (
		<div className="profile">
			<div className="link-container">
				<NavLink variant="outline-dark" to={PROFILE_ROUTES.PERSONAL_DETAILS} className="nav-link">
					Personal Details
				</NavLink>
				<NavLink to={PROFILE_ROUTES.ADDRESS} className="nav-link">
					Address
				</NavLink>
				<NavLink variant="outline-dark" to={PROFILE_ROUTES.EMAIL} className="nav-link">
					Email
				</NavLink>
				<NavLink to={PROFILE_ROUTES.PASSWORD} className="nav-link">
					Password
				</NavLink>
				<NavLink to={PROFILE_ROUTES.UPLOAD_DOCUMENTS} className="nav-link">
					Upload Document
				</NavLink>
			</div>
			<div className="route-container">
				<Switch>
					<Route path={PROFILE_ROUTES.HOME} exact component={ProfileHome} />
					<Route path={PROFILE_ROUTES.PERSONAL_DETAILS} component={() => <PersonalDetails user={user} updateUser={updateUserAsync} />} />
					<Route path={PROFILE_ROUTES.ADDRESS} component={() => <ProfileAddress user={user} />} />
					<Route path={PROFILE_ROUTES.EMAIL} component={() => <ProfileEmail user={user} />} />
					<Route path={PROFILE_ROUTES.PASSWORD} component={() => <ProfilePassword user={user} />} />
					<Route path={PROFILE_ROUTES.UPLOAD_DOCUMENTS} component={() => <UploadDocuments user={user} />} />
				</Switch>
			</div>
		</div>
	);
}

export default Profile;
