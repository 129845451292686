import { Button, Card } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import Input from 'cw-demowallet-common/src/components/input/Input';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../../App';
import React from 'react';
import * as Yup from 'yup';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import moment from 'moment';
import { generatePath } from 'react-router';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import './PasswordForgotten.scss';

const resetSchema = Yup.object().shape({
	newPassword: Yup.string().required('Please enter your new password.').min(8, 'Please enter at least 8 characters'),
	repeatPassword: Yup.string()
		.required('Please enter your repeat new password.')
		.oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
});

function PasswordForgotten() {
	const queryParams = useLocation().search;
	const verificationToken = new URLSearchParams(queryParams).get('verificationToken');
	const reactHistory = useHistory();
	const { apiClient } = useApi();
	const dispatch = useDispatch();
	const [cookies] = useCookies();
	const handleResetAsync = async (formData) => {
		try {
			const response = await apiClient({
				method: 'POST',
				data: {
					processToken: verificationToken,
					newPassword: formData.newPassword,
					userIdentifier: cookies.email,
				},
				url: generatePath(API_ENDPOINTS.PASSWORD_RECOVERY_FINISH),
			});
			if (response.data.status === 'USED' && moment().isBefore(moment(response.data.validUntil))) {
				dispatch(
					showToastMessage({
						title: 'Success',
						message: 'Password changed. You can now login with your new credentials.',
					}),
				);
				reactHistory.push(APP_ROUTES.LOGIN);
			}
		} catch {
			dispatch(
				showToastMessage({
					title: 'Error',
					message: 'Password could not be reset',
				}),
			);
		}
	};

	return (
		<section className="password-forgotten">
			<Card>
				<Card.Body>
					<h3>Password Forgotten</h3>
					<Formik validationSchema={resetSchema} initialValues={{ newPassword: '', repeatPassword: '' }} onSubmit={handleResetAsync}>
						<Form>
							<Input label="Password" name="newPassword" type="password" placeholder="New Password..." />
							<Input label="Repeat Password" name="repeatPassword" type="password" placeholder="Repeat Password..." />
							<div className="text-center">
								<Button variant="primary" type="submit">
									Set new password
								</Button>
								<Link to={APP_ROUTES.LOGIN}>Go back to login</Link>
							</div>
						</Form>
					</Formik>
				</Card.Body>
			</Card>
		</section>
	);
}

export default PasswordForgotten;
