import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import AnnotatedJson from '../annotatedJson/AnnotatedJson';
import { apiCallRunning, changeApiCallTab, hideApiCall } from 'cw-demowallet-common/src/redux/actions';
import './AjaxAnnotation.scss';

function AjaxAnnotation() {
	const dispatch = useDispatch();
	const { apiCall } = useSelector((state) => state);

	const handleClose = () => dispatch(hideApiCall());
	const handleSubmit = () => dispatch(apiCallRunning(true));
	const handleTabSelect = (k) => dispatch(changeApiCallTab(k));

	return (
		<div className="ajax-annotation-window">
			<div className={'overlay' + (apiCall.show ? ' visible' : '')}></div>
			<div className="content">
				<div className="card bg-light">
					<div className="card-header">
						DemoWallet API insight
						{apiCall.title && <strong> {apiCall.title}</strong>}
					</div>
					{apiCall.show && (
						<>
							<div className="card-body">
								<h5 className="card-title query-path">
									{apiCall.type} /api/v2{apiCall.queryPath}
								</h5>
								<Tabs id="api-call-tabs" transition={false} activeKey={apiCall.activeTab} onSelect={handleTabSelect}>
									<Tab eventKey="description" title="Description">
										<div dangerouslySetInnerHTML={{ __html: apiCall.description }} />
									</Tab>
									<Tab eventKey="input" title="Request">
										<AnnotatedJson value={apiCall.parameters} annotations={apiCall.requestAnnotations} />
									</Tab>
									<Tab eventKey="output" title="Response">
										{apiCall.result && <AnnotatedJson value={apiCall.result} annotations={apiCall.responseAnnotations} />}
										{apiCall.error && <AnnotatedJson value={apiCall.error} annotations={apiCall.responseAnnotations} />}
									</Tab>
								</Tabs>
							</div>
							<div className="card-footer">
								<div className="submit">
									{apiCall.result ? (
										<button type="button" className="btn btn-primary" onClick={handleClose}>
											Continue
										</button>
									) : (
										<button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={apiCall.running}>
											Send
										</button>
									)}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default AjaxAnnotation;
