import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { APP_ROUTES, useQueryParams } from '../../App';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import Input from 'cw-demowallet-common/src/components/input/Input';
import { PURCHASE_ROUTES } from '../purchase/Purchase';
import { SERVICE_AREA_ROUTES } from 'cw-demowallet-common/src/serviceAreaRoutes';
import { generatePath } from 'react-router';
import { constructAddress } from 'cw-demowallet-common/src/utils';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import './Signup.scss';

export const VALID_PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const signUpSchema = Yup.object().shape({
	firstName: Yup.string().required('Please enter your first name.').max(255, 'Please enter a shorter first name.'),
	lastName: Yup.string().required('Please enter your last name.').max(255, 'Please enter a shorter last name.'),
	emailAddress: Yup.string().required('Please enter your email address.').max(255, 'Please enter a shorter email address.').email('Please enter a valid email address.'),
	countrySelector: Yup.string().required('Please select the country').oneOf(['US', 'DE']),
	phoneNumber: Yup.string().matches(VALID_PHONE_REGEX, 'Please enter a valid phone number.'),
	password: Yup.string().required('Please enter your password.').min(8, 'Password is too short - should be 8 chars minimum.'),
	repeatedPassword: Yup.string()
		.required('Please enter your password repeated.')
		.oneOf([Yup.ref('password'), null], 'Passwords do not match'),
});

function Signup() {
	const dispatch = useDispatch();
	const reactHistory = useHistory();
	const queryParams = useQueryParams();
	const { apiClient, apiClientWithDialog, setAuthentication } = useApi();

	const handleSignUpAsync = async (formData, { setErrors }) => {
		setAuthentication(null);
		const purchaseId = queryParams.get('purchaseId');
		const isMarketplace = queryParams.get('isMarketplace');
		let purchase = null;
		if (purchaseId) {
			try {
				purchase = await apiClient({
					method: 'GET',
					url: generatePath(isMarketplace ? API_ENDPOINTS.PURCHASE_PUBLIC_GET : API_ENDPOINTS.MARKETPLACE_PURCHASE_PUBLIC_GET, { purchaseId }),
				})?.data;
			} catch {
				dispatch(
					showToastMessage({
						title: 'Unexpected exception',
						message: 'An exception was raised while calling the Service: Get Purchase',
					}),
				);
			}
		}
		try {
			const response = await apiClientWithDialog({
				withCredentials: false,
				title: 'Create Full Wallet',
				description:
					'<p>The consumer chose to <strong>continue as a registered User</strong>. Now a dedicated <strong>full</strong> wallet will be created.</p>' +
					'<p>The following data needs to be stored, in order to create a <strong>full wallet</strong> for this consumer:' +
					"<li>the consumer's <strong>country code</strong>. This is used to determine the subsidiary under which the full wallet should be created and to consider country-specific configurations, like limits.</li>" +
					"<li>the consumer's <strong>email address</strong>. This is a unique identifier and gets checked for uniqueness by the DemoWallet system</li>" +
					"<li>the consumer's <strong>phone number</strong>. This is a unique identifier and gets checked for uniqueness by the DemoWallet system</li>" +
					"<li>the consumer's <strong>first name</strong>. </li>" +
					"<li>the consumer's <strong>last name</strong>. </li>" +
					"<li>the consumer's <strong>language</strong>. </li>" +
					"<li>the consumer's <strong>password</strong>. </li>" +
					'</p>' +
					'<p>For this demonstration, we only store the bare minimum of data needed for the full wallet. It is also possible to additionally store a primary phone number</p>',
				requestAnnotations: {
					countryCode: "The consumer's country code is used to determine the DemoWallet's subsidiary under which the wallet should be created and to consider country-specific configurations, like limits.",
					emailAddress: "The consumer's e-mail address.",
					phoneNumber: "The consumer's phone number.",
					firstName: "The consumer's first name.",
					lastName: "The consumer's last name.",
					language: "The consumer's language preference.",
					password: "The consumer's password.",
				},
				responseAnnotations: {
					wallet: 'This attribute contains all necessary information about the created wallet.',
					subsidiaryId: 'This is the subsidiary to which the newly created wallet belongs. CoreWallet uses subsidiaries to allow multi-tenancy.',
					walletType: 'The type of wallet can be either Full, Guest or System.',
					accounts: 'A wallet can contain an arbitrary amount of accounts. In this case, one default account was created for the user.',
					currency: 'Wallet accounts can exist in any currency supported by the system.',
					accountBalance: 'The available balance is separated into directly available balance and balance which is already reserved for a specific usage.',
					auth: 'This authentication must be used for all subsequent API calls on behalf of the newly registered user.',
					error: 'An error has occurred, the flow cannot proceed and the UI will attempt to handle the error accordingly.',
					code: 'This is the error code the UI will respond to.',
				},
				type: 'POST',
				queryPath: API_ENDPOINTS.WALLET_REGISTER,
				parameters: {
					countryCode: formData.countrySelector,
					emailAddress: formData.emailAddress,
					phoneNumber: formData.phoneNumber,
					firstName: formData.firstName,
					lastName: formData.lastName,
					language: 'EN',
					password: formData.password,
					billingAddress: constructAddress(purchase?.billingAddress, 'DE', 'BILLING'),
					shippingAddress: constructAddress(purchase?.shippingAddress, 'DE', 'SHIPPING'),
				},
			});
			signUpSucceeded(response?.data, setErrors, purchaseId, isMarketplace);
		} catch (error) {
			signUpFailed(error, setErrors);
		}
	};

	const signUpSucceeded = (signUpData, setErrors, purchaseId, isMarketplace) => {
		if (signUpData?.walletId && signUpData?.authentication?.apiKey) {
			setAuthentication({
				apiKey: signUpData.authentication.apiKey,
				walletId: signUpData.walletId,
			});
			if (purchaseId) {
				reactHistory.push(generatePath(isMarketplace ? PURCHASE_ROUTES.OPTIONS_MARKETPLACE : PURCHASE_ROUTES.OPTIONS, { purchaseId }));
			} else {
				reactHistory.push(SERVICE_AREA_ROUTES.HOME);
			}
		}
	};

	const signUpFailed = (error, setErrors) => {
		dispatch(
			showToastMessage({
				title: 'Unexpected exception',
				message: 'An exception was raised while trying to sign up',
			}),
		);
	};

	return (
		<section className="signup">
			<Card>
				<Card.Body>
					<h3>Register for a new DemoWallet account</h3>
					<Formik
						validationSchema={signUpSchema}
						onSubmit={handleSignUpAsync}
						initialValues={{
							firstName: '',
							lastName: '',
							emailAddress: '',
							phoneNumber: '',
							password: '',
							countrySelector: '',
							repeatedPassword: '',
						}}
					>
						<Form>
							<Row>
								<Col xs={6}>
									<Input label="First Name" name="firstName" type="text" id="firstName" placeholder="Your first name..." />
								</Col>
								<Col xs={6}>
									<Input label="Last Name" name="lastName" type="text" id="lastName" placeholder="Your last name..." />
								</Col>
							</Row>
							<Input label="E-Mail Address" name="emailAddress" type="email" id="emailAddress" placeholder="Your e-mail address..." />
							<Input label="Phone Number (optional)" name="phoneNumber" type="tel" placeholder="Your phone number..." />
							<label className={'title'}>Select Country</label>
							<Field type="select" as="select" className="browser-default custom-select" name="countrySelector">
								<option value="-">-</option>
								<option value="DE">DE</option>
								<option value="US">US</option>
							</Field>
							<Row>
								<Col xs={6}>
									<Input label="Password" name="password" type="password" placeholder="Your password..." />
								</Col>
								<Col xs={6}>
									<Input label="Repeat Password" name="repeatedPassword" type="password" placeholder="Your password repeated..." />
								</Col>
							</Row>
							<div className="text-center">
								<Button variant="primary" type="submit">
									Sign-up now
								</Button>
								<p>
									...or <Link to={APP_ROUTES.LOGIN}>log-in</Link>
								</p>
							</div>
						</Form>
					</Formik>
				</Card.Body>
			</Card>
		</section>
	);
}

export default Signup;
