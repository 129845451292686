import { Button, Card } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import './UploadDocuments.scss';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import moment from 'moment';
import DocumentModal from './documentModal/DocumentModal';
import RevokeDocument from './revokeDocument/RevokeDocument';

function UploadDocuments({ user }) {
	const { apiClient } = useApi();
	const dispatch = useDispatch();
	const [documents, setDocuments] = useState([]);
	const [documentType, setDocumentType] = useState();
	const [currentDocument, setCurrentDocument] = useState();
	const [showDocumentModal, setShowDocumentModal] = useState(false);
	const [showRevokeModal, setShowRevokeModal] = useState(false);
	const updateDocumentAsync = useCallback(async () => {
		try {
			const response = await apiClient({
				method: 'GET',
				url: generatePath(API_ENDPOINTS.USER_DOCUMENT, { userId: user.id }),
				params: {
					states: 'Created|Accepted',
				},
			});
			setDocuments(response?.data);
		} catch {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'An exception was raised while calling the Service: Get Documents',
				}),
			);
		}
	}, [user, dispatch, apiClient]);

	const showUpModal = (type) => {
		setShowDocumentModal(true);
		setDocumentType(type);
	};
	useEffect(() => updateDocumentAsync(), [updateDocumentAsync]);

	const displayDocument = (document) => {
		return (
			<>
				<strong> {document.qualifier} </strong> - File uploaded by you: <strong>{document.originalFileName}</strong> on: <strong> {moment(document.creationDateTime).format('MM/DD/YYYY HH:mm:ss')} </strong> is in status:{' '}
				<strong>{document.status}</strong>.
			</>
		);
	};

	return (
		<div>
			<Card className="upload-documents">
				<Card.Header>Upload document for verifications</Card.Header>
				<Card.Body>
					<div className="radio-box">
						{documents.map((document, index) => (
							<div key={index}>
								<input type="radio" name="address" value={document} onChange={() => setCurrentDocument(document)} checked={document.id === currentDocument?.id} />
								{displayDocument(document)}
							</div>
						))}
					</div>
					<DocumentModal hideUploadModal={() => setShowDocumentModal(false)} show={showDocumentModal} user={user} documentType={documentType} updateDocumentAsync={updateDocumentAsync} />
					<RevokeDocument hideUploadModal={() => setShowRevokeModal(false)} show={showRevokeModal} selectedDocument={currentDocument} updateDocumentAsync={updateDocumentAsync} />
					<div className="button-container">
						<Button variant="primary" className="document-button" type="submit" onClick={() => showUpModal('UtilityBill')}>
							Upload utility document
						</Button>
						<Button variant="primary" className="document-button" type="submit" onClick={() => showUpModal('PassportCopy')}>
							Upload passport document
						</Button>
						<Button variant="outline-dark" className=" document-button" type="submit" disabled={!currentDocument} onClick={() => setShowRevokeModal(true)}>
							Revoke document
						</Button>
					</div>
				</Card.Body>
			</Card>
		</div>
	);
}

export default UploadDocuments;
