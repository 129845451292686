export const API_ENDPOINTS = {
	AUTHENTICATION_LOGIN: '/auth/authentications:login',
	WALLET_REGISTER: '/demowallet/wallet/wallets:register',
	PURCHASE_PUBLIC_GET: '/demowallet/wallet/tx/purchases/:purchaseId/public',
	PURCHASE_GET: '/demowallet/wallet/tx/purchases/:purchaseId',
	WALLET_GET: '/wallet/wallets/:walletId',
	SHOP_CONFIGS_LIST: '/demowallet/shop/configurations',
	SHOP_CONFIG_BY_ID_GET: '/demowallet/shop/configurations/:shopConfigurationId',
	SHOP_CONFIGS_AUTHENTICATIONS_LIST: '/demowallet/shop/configurations/:shopConfigurationId/authentications',
	USER_GET: '/user/users/:userId',
	ACCOUNT_ITEMS_GET: '/wallet/wallets/accounts/:accountId/items',
	GET_TRANSACTION_P2P: '/demowallet/wallet/tx/p2p/:transactionId',
	CANCEL_TRANSACTION_P2P: '/demowallet/wallet/tx/p2p/:transactionId\\:cancel',
	CREATE_PAYMENT_SESSION: '/wallet/tx/payments/session',
	CONFIRM_PAYMENT: '/wallet/tx/payments/:paymentId\\:confirm',
	UPDATE_PERSONAL_DETAILS: '/user/users/:userId/userdata/',
	CHANGE_PASSWORD: '/wallet/passwords/',
	USER_ADDRESSES: '/user/users/:userId/addresses/',
	USER_ADDRESS: '/user/users/:userId/addresses/:addressId',
	EMAIL_ADDRESS: '/user/users/:userId/emailaddresses',
	DELETE_EMAIL_ADDRESS: '/user/users/:userId/emailaddresses/:emailId',
	START_VERIFY_EMAIL_ADDRESS: '/user/users/:userId/emailaddresses\\:startVerification/',
	COMPLETE_VERIFY_EMAIL_ADDRESS: '/user/users/:userId/emailaddresses\\:finishVerification/',
	SET_EMAIL_ADDRESS_PRIMARY: '/user/users/:userId/emailaddresses/:emailId\\:setPrimary/',
	PURCHASE_PREPARE_CONFIRM: '/demowallet/wallet/tx/purchases/:purchaseId\\:prepareConfirm',
	PURCHASE_CONFIRM: '/demowallet/wallet/tx/purchases/:purchaseId\\:confirm',
	REDEEM_VOUCHER: '/demowallet/wallet/tx/vouchers:redeem',
	LOYALTY_PUBLIC_DATA: '/demowallet/wallet/tx/loyalty/programs/publicData',
	USER_DOCUMENT: '/user/users/:userId/iddocuments',
	REVOKE_USER_DOCUMENT: '/user/users/iddocuments/:documentId\\:revoke',
	WALLET_REGISTER_GUEST: '/demowallet/wallet/wallets:registerGuest',
	PURCHASE_CREATE_SESSION: '/demowallet/wallet/tx/purchases/:purchaseId/session',
	EMAIL_START_VERIFICATION: '/user/users/:userId/emailaddresses\\:startVerification',
	LOYALTY_MEMBERSHIPS: '/wallet/tx/loyalty/memberships?customerWalletId=:customerWalletId&loyaltyProgramId=:loyaltyProgramId',
	P2P_TRANSFER: '/demowallet/wallet/tx/p2p',
	PASSWORD_RECOVERY: '/wallet/passwords/recoveries',
	PASSWORD_RECOVERY_FINISH: '/wallet/passwords/recoveries\\:finish',
	ACCEPT_WALLET_P2P_TRANSFER: '/demowallet/wallet/tx/p2p/:p2pTxId\\:accept',
	REJECT_WALLET_P2P_TRANSFER: '/demowallet/wallet/tx/p2p/:p2pTxId\\:decline',
	MARKETPLACE_PURCHASE_GET: '/demowallet/marketplaces/purchase/:purchaseId',
	MARKETPLACE_PURCHASE_PUBLIC_GET: '/demowallet/marketplaces/purchase/:purchaseId/public',
	MARKETPLACE_PURCHASE_CREATE_SESSION: '/demowallet/marketplaces/purchase/:purchaseId/session',
	MARKETPLACE_PURCHASE_PREPARE_CONFIRM: '/demowallet/marketplaces/purchase/:purchaseId\\:prepareConfirm',
	MARKETPLACE_PURCHASE_CONFIRM: '/demowallet/marketplaces/purchase/:purchaseId\\:confirm',
	ADYEN_VALIDATE_3DS2: '/demowallet/psp/adyen/validate-authentication',
	GET_SELLERS_INFO_PUBLIC: '/demowallet/marketplaces/sellers/public',
};
