import { Button, Col, FormControl, FormLabel, Modal, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import Input from 'cw-demowallet-common/src/components/input/Input';
import React, { useCallback, useEffect, useState } from 'react';
import './AddressModal.scss';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { countryList } from 'cw-demowallet-common/src/utils';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PROFILE_ROUTES } from 'cw-demowallet-common/src/profileRoutes';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';

function AddressModal(props) {
	const dispatch = useDispatch();
	const { user, updateAddressAsync, match } = props;
	const [editableAddress, setEditableAddress] = useState();
	const addressId = match?.params?.id;
	const reactHistory = useHistory();
	const { apiClient, apiClientWithDialog } = useApi();

	const getCurrentAddressAsync = useCallback(
		async (addressId) => {
			try {
				const addressResponse = await apiClient({
					method: 'GET',
					url: generatePath(API_ENDPOINTS.USER_ADDRESSES, { userId: user.id }),
				});
				const currentAddress = addressResponse?.data?.filter((e) => e.id === addressId)[0];
				setEditableAddress(currentAddress);
			} catch {
				dispatch(
					showToastMessage({
						title: 'Unexpected exception',
						message: 'An exception was raised while calling the Service: Get Addresses',
					}),
				);
			}
		},
		[user, dispatch, apiClient],
	);

	useEffect(() => {
		if (addressId) {
			getCurrentAddressAsync(addressId);
		}
	}, [addressId, getCurrentAddressAsync]);

	const handleAddAddressAsync = async (formData) => {
		if (addressId) {
			await apiClientWithDialog({
				title: 'Updated Address',
				description: '<p>The <strong>Update Address</strong> API is used to update an existing address for an existing user.</p>',
				requestAnnotations: {
					addressLine1: 'Address Line 1 of the existing address to be updated.',
					addressLine2: 'Address Line 2 of the existing address to be updated.',
					addressType: 'Address type of the existing address to be updated, e.g. BILLING or SHIPPING.',
					alias: 'Alias of the existing address to be updated.',
					city: 'City of the the existing address to be updated.',
					country: 'Country of the existing address to be updated.',
					district: 'District of the existing address to be updated.',
					firstName: 'First name of the existing address to be updated.',
					houseNumber: 'House number of the existing address to be updated.',
					lastName: 'Last name of the existing address to be updated.',
					province: 'Province of the existing address to be updated.',
					setPrimary: 'Set the existing address to be updated as primary.',
					zip: 'ZIP code of the existing address to be updated.',
				},
				responseAnnotations: {
					publicId: 'ID of the shipping address.',
					type: 'Type of the address(shipping,billing).',
					value: 'Values of the updated address.',
					updatedItemId: 'Id of the address.',
					status: 'Returns the status of the current address.',
				},
				type: 'PUT',
				queryPath: generatePath(API_ENDPOINTS.USER_ADDRESS, { userId: user.id, addressId }),
				parameters: {
					addressLine1: formData.addressLine1,
					addressLine2: formData.addressLine2,
					addressType: type,
					alias: formData.addressAlias,
					city: formData.city,
					country: selectedCountry,
					district: formData.district,
					firstName: formData.firstName,
					houseNumber: formData.houseNumber,
					lastName: formData.lastName,
					province: formData.province,
					setPrimary: formData.setPrimary,
					zip: formData.zip,
				},
			});
		} else {
			await apiClientWithDialog({
				title: 'Add Address',
				description: '<p>The <strong>Add Address</strong> API is used to add a new address for an existing user.',
				requestAnnotations: {
					addressLine1: 'Address Line 1 of the new address to be added.',
					addressLine2: 'Address Line 2 of the new address to be added.',
					addressType: 'Address type of the new address to be added, e.g. BILLING or SHIPPING.',
					alias: 'Alias of the new address to be added.',
					city: 'City of the newly created address.',
					country: 'Country of the new address to be added.',
					district: 'District of the new address to be added.',
					firstName: 'First name of the new address to be added.',
					houseNumber: 'House number of the new address to be added.',
					lastName: 'Last name of the new address to be added.',
					province: 'Province of the new address to be added.',
					setPrimary: 'Set the new address to be added as primary.',
					zip: 'ZIP code of the new address to be added.',
				},
				responseAnnotations: {
					id: 'The ID of the newly created address.',
					creationDateTime: 'Date and time when the address was created.',
					verified: 'Whether or not the address was verified, false for newly added address.',
					standard: 'Returns true, if the newly created address is the primary address.',
					status: 'Returns the status of the address, active for newly added address.',
				},
				type: 'POST',
				queryPath: generatePath(API_ENDPOINTS.USER_ADDRESSES, { userId: user.id }),
				parameters: {
					addressLine1: formData.addressLine1,
					addressLine2: formData.addressLine2,
					addressType: type,
					alias: formData.addressAlias,
					city: formData.city,
					country: selectedCountry,
					district: formData.district,
					firstName: formData.firstName,
					houseNumber: formData.houseNumber,
					lastName: formData.lastName,
					province: formData.province,
					setPrimary: formData.setPrimary,
					zip: formData.zip,
				},
			});
		}

		updateAddressAsync();
		closeModal();
	};

	const addressTypes = [
		{ id: 'STANDARD', label: 'Standard' },
		{ id: 'BILLING', label: 'Billing' },
		{ id: 'SHIPPING', label: 'Shipping' },
	];

	const [type, setType] = useState('STANDARD');
	const [selectedCountry, setSelectedCountry] = useState();

	const closeModal = () => reactHistory.push(PROFILE_ROUTES.ADDRESS);

	return (
		<Modal show={true} className="add-address" onHide={closeModal}>
			<Modal.Body>
				{(!addressId || (addressId && editableAddress)) && (
					<Formik
						initialValues={{
							firstName: editableAddress ? editableAddress.firstName : '',
							lastName: editableAddress ? editableAddress.lastName : '',
							type: editableAddress ? editableAddress.type : '',
							addressLine1: editableAddress ? editableAddress.addressLine1 : '',
							addressLine2: editableAddress ? editableAddress.addressLine2 : '',
							zip: editableAddress ? editableAddress.zip : '',
							city: editableAddress ? editableAddress.city : '',
							province: editableAddress ? editableAddress.province : '',
							houseNumber: editableAddress ? editableAddress.houseNumber : '',
							addressAlias: editableAddress ? editableAddress.addressAlias : '',
						}}
						onSubmit={handleAddAddressAsync}
					>
						<Form>
							<div>
								<FormLabel>
									<strong>Type</strong>
								</FormLabel>
								<FormControl as="select" onChange={(e) => setType(e.target.value)} value={type}>
									{addressTypes.map((addressType, index) => (
										<option key={index} value={addressType.id}>
											{addressType.label}
										</option>
									))}
								</FormControl>

								<Row>
									<Col xs={12}>
										<Input label="Alias" name="addressAlias" type="text" placeholder="Address alias" />
									</Col>
								</Row>

								<Row>
									<Col xs={6}>
										<Input label="First Name" name="firstName" type="text" placeholder="Your first name..." />
									</Col>
									<Col xs={6}>
										<Input label="Last Name" name="lastName" type="text" placeholder="Your last name..." />
									</Col>
								</Row>
								<Row>
									<Col xs={9}>
										<Input label="Address Line 1" name="addressLine1" type="text" placeholder="Address Line 1" />
									</Col>
									<Col xs={3}>
										<Input label="No." name="houseNumber" type="text" placeholder="No." />
									</Col>
								</Row>

								<Input label="Address Line 2" name="addressLine2" type="text" placeholder="Address Line 2" />

								<Row>
									<Col xs={4}>
										<Input label="Zip" name="zip" type="text" placeholder="Zip" />
									</Col>
									<Col xs={8}>
										<Input label="City" name="city" type="text" placeholder="City" />
									</Col>
								</Row>

								<Row>
									<Col xs={6}>
										<Input label="District" name="district" type="text" placeholder="District" />
									</Col>
									<Col xs={6}>
										<Input label="Province" name="province" type="text" placeholder="Province" />
									</Col>
								</Row>

								<FormLabel>
									<strong>Country</strong>
								</FormLabel>
								<FormControl as="select" onChange={(e) => setSelectedCountry(e.target.value)} value={selectedCountry}>
									<option disabled selected>
										Please select
									</option>
									{countryList.map((addressCountry, index) => (
										<option key={index} value={addressCountry.id}>
											{addressCountry.label}
										</option>
									))}
								</FormControl>

								<div className="button-container">
									<Button variant="primary" className="ops-button" type="submit">
										{addressId ? 'Update Address' : 'Add Address'}
									</Button>
									<Button variant="outline-dark" className="ops-button" type="button" onClick={closeModal}>
										Close
									</Button>
								</div>
							</div>
						</Form>
					</Formik>
				)}
			</Modal.Body>
		</Modal>
	);
}
export default AddressModal;
