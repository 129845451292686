import { Link, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../../../App';
import { Card, Container } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { generatePath } from 'react-router';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import './ServiceAreaVerifyEmail.scss';

function ServiceAreaVerifyEmail({ user }) {
	const queryParams = useLocation().search;
	const verificationToken = new URLSearchParams(queryParams).get('verificationToken');
	const [status, setStatus] = useState('Pending');
	const [errorMessage, setErrorMessage] = useState();
	const { apiClient } = useApi();

	const isAuthenticationFailed = (error) => {
		return error && error.error === 'AuthenticationError';
	};
	const isEmailAlreadyVerified = (error) => {
		return error && error.error === 'UnverifiedEmailNotFound';
	};
	const isVerificationAttemptFailed = (error) => {
		return error && error.error === 'VerificationAttemptFailed';
	};

	const verifyEmailAsync = useCallback(async () => {
		try {
			await apiClient({
				method: 'POST',
				data: {
					emailAddress: user.primaryEmail,
					verificationToken: verificationToken,
				},
				url: generatePath(API_ENDPOINTS.COMPLETE_VERIFY_EMAIL_ADDRESS, { userId: user.id }),
			});
			setStatus('Success');
		} catch (error) {
			setStatus('Failed');
			if (isAuthenticationFailed(error.response.error)) {
				setErrorMessage('Authentication Failed. Please make sure that you are logged in with the correct account to complete the verification process.');
			} else if (isEmailAlreadyVerified(error.response.error)) {
				setErrorMessage('Your email address has already been verified successfully.');
			} else if (isVerificationAttemptFailed(error.response.error)) {
				setErrorMessage('Your email address could not be verified. Please make sure that you are logged in with the correct account.');
			} else {
				setErrorMessage('Your email address could not be verified. Please contact DemoWallet support.');
			}
		}
	}, [user, verificationToken, apiClient]);

	useEffect(() => verifyEmailAsync(), [verifyEmailAsync]);

	return (
		<section className="service-area-verify-email">
			<Container>
				<Card>
					<Card.Body>
						<h3>Email Verification</h3>
						{status === 'Pending' && (
							<>
								<i className="fa fa-spin fa-spinner"></i>
								<p>Please wait while your email address is being verified...</p>
							</>
						)}
						{status === 'Failed' && (
							<>
								<i className="fa fa-exclamation-triangle"></i>
								<p>{errorMessage}</p>
							</>
						)}
						{status === 'Success' && (
							<>
								<i className="fa fa-check"></i>
								<p>
									Your email address was verified successfully. Please click <Link to={APP_ROUTES.SERVICE_AREA}>here</Link> to go back to Overview.
								</p>
							</>
						)}
					</Card.Body>
				</Card>
			</Container>
		</section>
	);
}

export default ServiceAreaVerifyEmail;
