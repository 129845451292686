import { Button, Card, FormControl, FormLabel } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import Input from 'cw-demowallet-common/src/components/input/Input';
import React, { useEffect, useState } from 'react';
import './PersonalDetails.scss';
import * as Yup from 'yup';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { generatePath } from 'react-router';
import moment from 'moment';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { countryList } from 'cw-demowallet-common/src/utils';

function PersonalDetails({ user, updateUser }) {
	const { apiClientWithDialog } = useApi();
	const [citizenship, setCitizenship] = useState();

	const personalDetailsValidation = Yup.object().shape({
		dateOfBirth: Yup.date().required('Enter your date of birth.'),
	});

	const handleSubmitAsync = async (formData) => {
		await apiClientWithDialog({
			title: 'Update details',
			description: '<p>The <strong>Update details</strong> API is used for updating the personal details of an existing user.',
			requestAnnotations: {
				citizenship: 'The country where the user has citizenship.',
				dateOfBirth: 'Date of birth of the user.',
			},
			responseAnnotations: {
				entries: 'This list contains the list of entries which are successfully updated.',
			},
			type: 'PUT',
			queryPath: generatePath(API_ENDPOINTS.UPDATE_PERSONAL_DETAILS, {
				userId: user.id,
			}),
			parameters: {
				citizenship: citizenship,
				dateOfBirth: moment(formData.dateOfBirth).format('YYYY-MM-DDThh:mm:ss[Z]'),
			},
		});
		updateUser();
	};

	const selectCitizenship = (e) => {
		setCitizenship(e.target.value);
	};
	useEffect(() => {
		setCitizenship(user.citizenship);
	}, [user]);

	return (
		<div className="personal-details">
			<Card>
				<Card.Header>Update your personal details</Card.Header>
				<Card.Body>
					<Formik
						validationSchema={personalDetailsValidation}
						initialValues={{
							dateOfBirth: moment(user.dateOfBirth).format('yyyy-MM-DD'),
						}}
						onSubmit={handleSubmitAsync}
					>
						<Form>
							<FormLabel>
								<strong>Nationality</strong>
							</FormLabel>
							<FormControl as="select" onChange={selectCitizenship} value={citizenship}>
								<option disabled selected>
									Please select
								</option>
								{countryList.map((country, index) => (
									<option key={index} value={country.id}>
										{country.label}
									</option>
								))}
							</FormControl>
							<Input label="Date of birth" name="dateOfBirth" type="date" placeholder="DD/MM/YYYY" />
							<div className="text-center">
								<Button variant="primary" type="submit">
									Update values
								</Button>
							</div>
						</Form>
					</Formik>
				</Card.Body>
			</Card>
		</div>
	);
}

export default PersonalDetails;
