import React from 'react';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import reducer from 'cw-demowallet-common/src/redux/reducer';

const store = createStore(reducer, applyMiddleware(thunk));

function AppContext({ children }) {
	return (
		<Provider store={store}>
			<Router basename={process.env.PUBLIC_URL}>{children}</Router>
		</Provider>
	);
}

export default AppContext;
