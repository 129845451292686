import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Card, Col, Image, OverlayTrigger, Popover, PopoverContent, PopoverTitle, Row } from 'react-bootstrap';
import Input from 'cw-demowallet-common/src/components/input/Input';
import * as Yup from 'yup';
import cardValidator from 'card-validator';
import { ErrorMessage, Field, Formik } from 'formik';
import './CreditCardOption.scss';
import { PAYMENT_OPTION_CONSTANTS } from '../PaymentOptionConstants';
import InputMask from 'react-input-mask';

const CreditCardOption = forwardRef(({ isActive, triggerPayment, paymentMethod }, ref) => {
	const formRef = useRef();

	useImperativeHandle(ref, () => ({
		startPayment: async () => await formRef.current.submitForm(),
	}));

	const handleFormSubmit = (value) => {
		let paymentDetails = {
			selectedPaymentMethod: PAYMENT_OPTION_CONSTANTS.CREDIT_CARD,
			selectedPaymentInstrumentId: value.selectedPaymentInstrumentId,
			cvv: value.cvv,
		};
		if (value.selectedPaymentInstrumentId === 'NEW') {
			const expirationDate = cardValidator.expirationDate(value.expiryDate);
			paymentDetails = {
				...paymentDetails,
				owner: value.nameOnCard,
				number: value.creditCardNumber.replaceAll(' ', ''),
				validToMonth: expirationDate.month,
				validToYear: expirationDate.year,
			};
		}
		triggerPayment(paymentDetails);
	};

	const paymentInstruments = paymentMethod?.paymentInstruments;

	const validationSchema = Yup.object().shape({
		selectedPaymentInstrumentId: Yup.string().required('Please choose one of the options'),
		cvv: Yup.string()
			.required('Please enter a CVV.')
			.test('card-cvv-internal-id', 'Please enter a valid CVV.', (cardNum) => cardValidator.cvv(cardNum).isValid),
		creditCardNumber: Yup.string().when('selectedPaymentInstrumentId', (instrument, schema) =>
			instrument !== 'NEW' ? schema : schema.required('Please enter a credit card number.').test('card-number-internal-id', 'Please enter a valid credit card number.', (cardNum) => cardValidator.number(cardNum).isValid),
		),
		expiryDate: Yup.string().when('selectedPaymentInstrumentId', (instrument, schema) =>
			instrument !== 'NEW' ? schema : schema.required('Please enter an expiry date.').test('card-exp-internal-id', 'Please enter a valid expiry date.', (cardExp) => cardValidator.expirationDate(cardExp).isValid),
		),
		nameOnCard: Yup.string().when('selectedPaymentInstrumentId', (instrument, schema) =>
			instrument !== 'NEW' ? schema : schema.required('Please enter a name.').test('card-name-internal-id', 'Please enter a valid name.', (cardName) => cardValidator.cardholderName(cardName).isValid),
		),
	});

	const CreditCardTooltip = () => (
		<OverlayTrigger
			delay={{ hide: 1000 }}
			placement="right"
			overlay={
				<Popover>
					<PopoverTitle>
						<small>Please use a test credit card number!</small>
					</PopoverTitle>
					<PopoverContent>
						Please read&nbsp;
						<a href="https://corewallet.trimplement.com/demo/using-credit-card-numbers" target="_new">
							the chapter on credit card handling
							<i className="fa fa-external-link" />
						</a>
						&nbsp; to learn about possible numbers and duplicate handling.
					</PopoverContent>
				</Popover>
			}
		>
			<i className="fa fa-info-circle" />
		</OverlayTrigger>
	);

	return (
		<div className="credit-card-option">
			<p>
				<Image alt="Credit Card logo" title="Credit Card" className="logo" src={`${process.env.PUBLIC_URL}/assets/images/pm_creditcard.png`} />
			</p>
			<p>
				We can charge your <strong>credit card</strong>. Your data will be securely stored.
			</p>
			{isActive && (
				<Formik
					innerRef={formRef}
					validationSchema={validationSchema}
					onSubmit={handleFormSubmit}
					initialValues={{
						selectedPaymentInstrumentId: paymentInstruments?.length === 0 ? 'NEW' : '',
						cvv: '',
						creditCardNumber: '',
						expiryDate: '',
						nameOnCard: '',
					}}
				>
					{(formik) => (
						<div>
							{paymentInstruments && <p>Please select one of your stored credit cards or add a new one:</p>}
							{paymentInstruments?.map((instrument, index) => (
								<div className="radio-box" key={index}>
									<Field type="radio" name="selectedPaymentInstrumentId" value={instrument.publicId} />
									<div>
										{instrument.publicFields.cardScheme} {instrument.publicFields.displayValue} (exp. {instrument.publicFields.validToMonth}/{instrument.publicFields.validToYear})
										{instrument.publicId === formik.values['selectedPaymentInstrumentId'] && <Input name="cvv" type="text" autoComplete="off" placeholder="CVV" className="cvv-single" />}
									</div>
								</div>
							))}
							<div className="radio-box">
								<Field type="radio" name="selectedPaymentInstrumentId" value="NEW" />
								<div>Add a new card</div>
							</div>
							{formik.values['selectedPaymentInstrumentId'] === 'NEW' && (
								<>
									<Card className="credit-card-box">
										<Row>
											<Col xs={12}>
												<Field name="creditCardNumber">
													{(fieldProps) => (
														<InputMask
															mask="9999 9999 9999 9999"
															maskChar=" "
															type="text"
															{...fieldProps.field}
															id="creditCardNumber"
															placeholder="1234 5678 9012 3456"
															autoComplete="off"
															label={
																<>
																	Card Number&nbsp;
																	<CreditCardTooltip />
																</>
															}
														>
															{(inputProps) => <Input {...inputProps} />}
														</InputMask>
													)}
												</Field>
											</Col>
										</Row>
										<Row>
											<Col xs={6}>
												<Field name="expiryDate">
													{(fieldProps) => (
														<InputMask mask="99 / 99" maskChar=" " label="Expiry Date" name="expiryDate" id="expiryDate" placeholder="MM / YY" autoComplete="off" type="text" {...fieldProps.field}>
															{(inputProps) => <Input {...inputProps} />}
														</InputMask>
													)}
												</Field>
											</Col>
											<Col xs={6}>
												<Input label="CVV" name="cvv" id="cvv" placeholder="123" autoComplete="off" />
											</Col>
										</Row>
										<Row>
											<Col xs={12}>
												<Input label="Name on Card" name="nameOnCard" id="nameOnCard" placeholder="John Doe" autoComplete="off" />
											</Col>
										</Row>
									</Card>
								</>
							)}
							<ErrorMessage name="selectedPaymentInstrumentId" component="div" className="invalid-feedback visible-error" />
						</div>
					)}
				</Formik>
			)}
		</div>
	);
});

export default CreditCardOption;
