import { formatAmount, formatTimestamp } from '../../../utils';
import { useCallback, useEffect, useState } from 'react';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { generatePath } from 'react-router';
import { Button, Modal } from 'react-bootstrap';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';
import { API_ENDPOINTS } from '../../../endpoints';

function AccountStatementP2POutgoing({ accountItem }) {
	const [p2pTransaction, setP2pTransaction] = useState();
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [isCanceling, setIsCanceling] = useState(false);
	const dispatch = useDispatch();
	const { apiClient, apiClientWithDialog } = useApi();

	const getP2PTransactionAsync = useCallback(async () => {
		try {
			const response = await apiClient({
				method: 'GET',
				url: generatePath(API_ENDPOINTS.GET_TRANSACTION_P2P, { transactionId: accountItem.transactionOwner?.id }),
			});
			setP2pTransaction(response?.data);
		} catch {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'An exception was raised while calling the Service: Get P2P',
				}),
			);
		}
	}, [accountItem, dispatch, apiClient]);

	useEffect(() => getP2PTransactionAsync(), [getP2PTransactionAsync]);

	const confirmCancelAsync = async () => {
		setIsCanceling(true);
		await apiClientWithDialog({
			title: 'Cancel P2P Transaction',
			description: '<p>As long as the recipient has not yet redeemed the P2P transaction, the sender can cancel the transaction at any time.</p>',
			requestAnnotations: {},
			responseAnnotations: {
				transactionStatus: 'The transaction status has now changed to Cancelled.',
			},
			type: 'POST',
			queryPath: generatePath(API_ENDPOINTS.CANCEL_TRANSACTION_P2P, { transactionId: p2pTransaction.id }),
			parameters: {},
		});
		setIsCanceling(false);
		setShowCancelModal(false);
	};

	const startCancel = () => setShowCancelModal(true);

	const closeCancelModal = () => {
		if (!isCanceling) {
			setShowCancelModal(false);
		}
	};

	return (
		<>
			<td>{formatTimestamp(accountItem.creationDateTime)}</td>
			<td>{formatAmount(accountItem.amount)}</td>
			<td>{accountItem.itemStatus}</td>
			<td>
				{p2pTransaction && (
					<p>
						P2P transfer to {p2pTransaction.targetUserIdentifier}: {accountItem.description}
						<br />
						{p2pTransaction.p2PTransaction?.transactionStatus === 'Confirmed' && (
							<button className="text-link" onClick={startCancel}>
								Cancel this transfer
							</button>
						)}
					</p>
				)}
				{!p2pTransaction && <i className="fa fa-spin fa-spinner"></i>}
			</td>
			<td>
				<a target="_new" href={`https://demowallet.trimplement.com/admin/p2p-transactions/${accountItem.transactionOwner.id}`}>
					<i className="fa fa-external-link"></i>
				</a>
			</td>
			<Modal show={showCancelModal} onHide={closeCancelModal}>
				<Modal.Header closeButton>
					<Modal.Title>Cancel transfer</Modal.Title>
				</Modal.Header>
				<Modal.Body>Do you really want to cancel this transfer? The recipient was already notified.</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeCancelModal} disabled={isCanceling}>
						No, back to overview
					</Button>
					<Button variant="primary" onClick={confirmCancelAsync} disabled={isCanceling}>
						Yes, cancel {isCanceling && <i className="fa fa-spin fa-spinner"></i>}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default AccountStatementP2POutgoing;
