import React, { forwardRef, useImperativeHandle } from 'react';
import { Image } from 'react-bootstrap';
import './PaypalOption.scss';
import { PAYMENT_OPTION_CONSTANTS } from '../PaymentOptionConstants';

const PaypalOption = forwardRef(({ isActive, triggerPayment, paymentMethod }, ref) => {
	useImperativeHandle(ref, () => ({
		startPayment: () => {
			triggerPayment({
				selectedPaymentMethod: PAYMENT_OPTION_CONSTANTS.PAYPAL,
				selectedPaymentInstrumentId: paymentMethod.paymentInstruments[0].publicId,
			});
		},
	}));

	return (
		<div className="paypal-option">
			<p>
				<Image alt="PayPal logo" title="PayPal" className="logo" src={`${process.env.PUBLIC_URL}/assets/images/pm_paypal.jpg`} />
			</p>
			<p>
				You can use <strong>PayPal</strong> to settle the payment amount either with your PayPal balance or by charging a payment instrument stored in your Paypal account.
			</p>
		</div>
	);
});

export default PaypalOption;
