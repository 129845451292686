import { Button, Card } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import './ProfileEmail.scss';
import AddEmail from './AddEmail/AddEmail';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { generatePath } from 'react-router';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { useDispatch } from 'react-redux';
import VerifyEmail from './EmailVerification/VerifyEmail';

function ProfileEmail({ user }) {
	const dispatch = useDispatch();
	const [emails, setEmails] = useState([]);
	const [currentEmail, setCurrentEmail] = useState();
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
	const { apiClient } = useApi();

	const updateEmailAsync = useCallback(async () => {
		try {
			const addressResponse = await apiClient({
				method: 'GET',
				url: generatePath(API_ENDPOINTS.EMAIL_ADDRESS, { userId: user.id }),
			});
			setEmails(addressResponse?.data);
		} catch {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'An exception was raised while calling the Service: Get Emails',
				}),
			);
		}
	}, [user, dispatch, apiClient]);

	useEffect(() => updateEmailAsync(), [updateEmailAsync]);

	const displayEmail = (emailObj) => {
		const emailType = `(${emailObj.standard ? 'Primary' : ''}${emailObj.verified ? ', Verified' : ''})`;
		return (
			<>
				<strong> {emailObj.email} </strong> {emailType.length === 2 ? '' : emailType}
			</>
		);
	};

	const deleteEmail = async () => {
		try {
			await apiClient({
				method: 'DELETE',
				url: generatePath(API_ENDPOINTS.DELETE_EMAIL_ADDRESS, { userId: user.id, emailId: currentEmail.id }),
			});
			dispatch(
				showToastMessage({
					title: 'Success',
					message: 'Email Deleted Successfully',
				}),
			);
			setCurrentEmail(null);
			updateEmailAsync();
		} catch {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'An exception was raised while calling the Service: Delete Email',
				}),
			);
		}
	};

	const startEmailVerification = async () => {
		try {
			await apiClient({
				method: 'POST',
				data: {
					emailAddress: currentEmail.email,
				},
				url: generatePath(API_ENDPOINTS.START_VERIFY_EMAIL_ADDRESS, { userId: user.id }),
			});
			dispatch(
				showToastMessage({
					title: 'Success',
					message: 'Email address verification started successfully',
				}),
			);
			await updateEmailAsync();
		} catch {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'An exception was raised while calling the Service: Delete Email',
				}),
			);
		}
	};

	const setAsPrimaryEmailAddress = async () => {
		try {
			await apiClient({
				method: 'POST',
				url: generatePath(API_ENDPOINTS.SET_EMAIL_ADDRESS_PRIMARY, { userId: user.id, emailId: currentEmail.id }),
			});
			dispatch(
				showToastMessage({
					title: 'Success',
					message: 'Email address made primary email successfully',
				}),
			);
			updateEmailAsync();
		} catch {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'An exception was raised while calling the Service: Make Primary Email',
				}),
			);
		}
	};

	return (
		<div className="profile-email">
			<Card>
				<Card.Header>Saved email addresses</Card.Header>
				<Card.Body>
					<div className="radio-box">
						{emails.map((email, index) => (
							<div key={index}>
								<input type="radio" name="address" value={email} onChange={() => setCurrentEmail(email)} checked={email.id === currentEmail?.id} />
								{displayEmail(email)}
							</div>
						))}
					</div>
					<div className="button-container">
						{showEmailModal && <AddEmail hideEmailModal={() => setShowEmailModal(false)} user={user} updateEmailDisplay={updateEmailAsync} />}
						{showVerifyEmailModal && <VerifyEmail hideEmailVerifyModal={() => setShowVerifyEmailModal(false)} user={user} currentEmail={currentEmail} />}
						<Button variant="primary" className="ops-button" type="submit" onClick={() => setShowEmailModal(true)}>
							Add new email address
						</Button>
						<Button variant="outline-dark" className="ops-button" type="submit" disabled={!currentEmail} onClick={deleteEmail}>
							Delete the selected email address
						</Button>
						<Button variant="outline-dark" className="ops-button" type="submit" disabled={!currentEmail} onClick={startEmailVerification}>
							Start verification
						</Button>
						<Button variant="outline-dark" className="ops-button" type="submit" disabled={!currentEmail} onClick={() => setShowVerifyEmailModal(true)}>
							Complete verification
						</Button>
						<Button variant="outline-dark" className="ops-button" type="submit" disabled={!currentEmail} onClick={setAsPrimaryEmailAddress}>
							Make this a primary email
						</Button>
					</div>
				</Card.Body>
			</Card>
		</div>
	);
}

export default ProfileEmail;
