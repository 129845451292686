import moment from 'moment';

export const formatAmount = (prop) => {
	if (!prop) {
		return '';
	}
	const { amount, currency } = prop;
	const parsedValue = Number(amount)
		.toFixed(2)
		.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
	let result = '';
	if (currency === 'GBP') {
		result = `£${parsedValue}`;
	} else if (currency === 'USD') {
		result = `$${parsedValue}`;
	} else if (currency === 'EUR') {
		result = `€${parsedValue}`;
	} else {
		result = `${parsedValue} ${currency}`;
	}
	return result;
};

export const addAmount = (amount1, amount2) => {
	if (amount1.currency !== amount2.currency) {
		return;
	}
	const sum = amount1.amount + amount2.amount;
	return {
		amount: sum,
		currency: amount2.currency,
	};
};

export const constructAddress = (addressJsonString, countryCode, type) => {
	if (addressJsonString) {
		try {
			return {
				...JSON.parse(addressJsonString),
				country: countryCode,
				type: type,
			};
		} catch (_) {
			// ignore
		}
	}
	return addressJsonString;
};

export const formatTimestamp = (value) => moment(value, "YYYY-MM-DD'T'HH:mm:ssZ").format('DD/MM/YYYY HH:mm:ss');

export const URIParams = (paramObj) =>
	Object.keys(paramObj)
		.map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(paramObj[k]))
		.join('&');

export const countryList = [
	{ id: 'AX', label: 'Aaland Islands' },
	{ id: 'AF', label: 'Afghanistan' },
	{ id: 'AL', label: 'Albania' },
	{ id: 'DZ', label: 'Algeria' },
	{ id: 'AS', label: 'American Samoa' },
	{ id: 'AD', label: 'Andorra' },
	{ id: 'AO', label: 'Angola' },
	{ id: 'AI', label: 'Anguilla' },
	{ id: 'AQ', label: 'Antarctica' },
	{ id: 'AG', label: 'Antigua and Barbuda' },
	{ id: 'AR', label: 'Argentina' },
	{ id: 'AM', label: 'Armenia' },
	{ id: 'AW', label: 'Aruba' },
	{ id: 'AU', label: 'Australia' },
	{ id: 'AT', label: 'Austria' },
	{ id: 'AZ', label: 'Azerbaijan' },
	{ id: 'BS', label: 'Bahamas' },
	{ id: 'BH', label: 'Bahrain' },
	{ id: 'BD', label: 'Bangladesh' },
	{ id: 'BB', label: 'Barbados' },
	{ id: 'BY', label: 'Belarus' },
	{ id: 'BE', label: 'Belgium' },
	{ id: 'BZ', label: 'Belize' },
	{ id: 'BJ', label: 'Benin' },
	{ id: 'BM', label: 'Bermuda' },
	{ id: 'BT', label: 'Bhutan' },
	{ id: 'BO', label: 'Bolivia' },
	{ id: 'BA', label: 'Bosnia and Herzegowina' },
	{ id: 'BW', label: 'Botswana' },
	{ id: 'BV', label: 'Bouvet Island' },
	{ id: 'BR', label: 'Brazil' },
	{ id: 'IO', label: 'British Indian Ocean Territory' },
	{ id: 'BN', label: 'Brunei Darussalam' },
	{ id: 'BG', label: 'Bulgaria' },
	{ id: 'BF', label: 'Burkina Faso' },
	{ id: 'BI', label: 'Burundi' },
	{ id: 'KH', label: 'Cambodia' },
	{ id: 'CM', label: 'Cameroon' },
	{ id: 'CA', label: 'Canada' },
	{ id: 'CV', label: 'Cape Verde' },
	{ id: 'KY', label: 'Cayman Islands' },
	{ id: 'CF', label: 'Central African Republic' },
	{ id: 'TD', label: 'Chad' },
	{ id: 'CL', label: 'Chile' },
	{ id: 'CN', label: 'China' },
	{ id: 'CX', label: 'Christmas Island' },
	{ id: 'CC', label: 'Cocos (keeling) Islands' },
	{ id: 'CO', label: 'Colombia' },
	{ id: 'KM', label: 'Comoros' },
	{ id: 'CD', label: 'Democratic Republic Of Congo (was Zaire)' },
	{ id: 'CG', label: 'Republic Of Congo' },
	{ id: 'CK', label: 'Cook Islands' },
	{ id: 'CR', label: 'Costa Rica' },
	{ id: 'CI', label: 'Cote Divoire' },
	{ id: 'HR', label: 'Croatia' },
	{ id: 'CU', label: 'Cuba' },
	{ id: 'CY', label: 'Cyprus' },
	{ id: 'CZ', label: 'Czech Republic' },
	{ id: 'DK', label: 'Denmark' },
	{ id: 'DJ', label: 'Djibouti' },
	{ id: 'DM', label: 'Dominica' },
	{ id: 'DO', label: 'Dominican Republic' },
	{ id: 'EC', label: 'Ecuador' },
	{ id: 'EG', label: 'Egypt' },
	{ id: 'SV', label: 'El Salvador' },
	{ id: 'GQ', label: 'Equatorial Guinea' },
	{ id: 'ER', label: 'Eritrea' },
	{ id: 'EE', label: 'Estonia' },
	{ id: 'ET', label: 'Ethiopia' },
	{ id: 'FK', label: 'Falkland Islands (Malvinas)' },
	{ id: 'FO', label: 'Faroe Islands' },
	{ id: 'FJ', label: 'Fiji' },
	{ id: 'FI', label: 'Finland' },
	{ id: 'FR', label: 'France' },
	{ id: 'GF', label: 'French Guiana' },
	{ id: 'PF', label: 'French Polynesia' },
	{ id: 'TF', label: 'French Southern Territories' },
	{ id: 'GA', label: 'Gabon' },
	{ id: 'GM', label: 'Gambia' },
	{ id: 'GE', label: 'Georgia' },
	{ id: 'DE', label: 'Germany' },
	{ id: 'GH', label: 'Ghana' },
	{ id: 'GI', label: 'Gibraltar' },
	{ id: 'GR', label: 'Greece' },
	{ id: 'GL', label: 'Greenland' },
	{ id: 'GD', label: 'Grenada' },
	{ id: 'GP', label: 'Guadeloupe' },
	{ id: 'GU', label: 'Guam' },
	{ id: 'GT', label: 'Guatemala' },
	{ id: 'GN', label: 'Guinea' },
	{ id: 'GW', label: 'Guinea-bissau' },
	{ id: 'GY', label: 'Guyana' },
	{ id: 'HT', label: 'Haiti' },
	{ id: 'HM', label: 'Heard And Mc Donald Islands' },
	{ id: 'HN', label: 'Honduras' },
	{ id: 'HK', label: 'Hong Kong' },
	{ id: 'HU', label: 'Hungary' },
	{ id: 'IS', label: 'Iceland' },
	{ id: 'IN', label: 'India' },
	{ id: 'ID', label: 'Indonesia' },
	{ id: 'IR', label: 'Islamic Republic Of Iran' },
	{ id: 'IQ', label: 'Iraq' },
	{ id: 'IE', label: 'Ireland' },
	{ id: 'IL', label: 'Israel' },
	{ id: 'IT', label: 'Italy' },
	{ id: 'JM', label: 'Jamaica' },
	{ id: 'JP', label: 'Japan' },
	{ id: 'JO', label: 'Jordan' },
	{ id: 'KZ', label: 'Kazakhstan' },
	{ id: 'KE', label: 'Kenya' },
	{ id: 'KI', label: 'Kiribati' },
	{ id: 'KP', label: 'Democratic Peoples Republic of Korea' },
	{ id: 'KR', label: 'Korea, Republic of' },
	{ id: 'KW', label: 'Kuwait' },
	{ id: 'KG', label: 'Kyrgyzstan' },
	{ id: 'LA', label: 'Lao Peoples Democratic Republic' },
	{ id: 'LV', label: 'Latvia' },
	{ id: 'LB', label: 'Lebanon' },
	{ id: 'LS', label: 'Lesotho' },
	{ id: 'LR', label: 'Liberia' },
	{ id: 'LY', label: 'Libyan Arab Jamahiriya' },
	{ id: 'LI', label: 'Liechtenstein' },
	{ id: 'LT', label: 'Lithuania' },
	{ id: 'LU', label: 'Luxembourg' },
	{ id: 'ME', label: 'Montenegro' },
	{ id: 'MO', label: 'Macau' },
	{ id: 'MK', label: 'The Former Yugoslav Republic of Macedonia' },
	{ id: 'MG', label: 'Madagascar' },
	{ id: 'MW', label: 'Malawi' },
	{ id: 'MY', label: 'Malaysia' },
	{ id: 'MV', label: 'Maldives' },
	{ id: 'ML', label: 'Mali' },
	{ id: 'MT', label: 'Malta' },
	{ id: 'MH', label: 'Marshall Islands' },
	{ id: 'MQ', label: 'Martinique' },
	{ id: 'MR', label: 'Mauritania' },
	{ id: 'MU', label: 'Mauritius' },
	{ id: 'YT', label: 'Mayotte' },
	{ id: 'MX', label: 'Mexico' },
	{ id: 'FM', label: 'Micronesia, Federated States of' },
	{ id: 'MD', label: 'Republic of Moldova' },
	{ id: 'MC', label: 'Monaco' },
	{ id: 'MN', label: 'Mongolia' },
	{ id: 'MS', label: 'Montserrat' },
	{ id: 'MA', label: 'Morocco' },
	{ id: 'MZ', label: 'Mozambique' },
	{ id: 'MM', label: 'Myanmar' },
	{ id: 'NA', label: 'Namibia' },
	{ id: 'NR', label: 'Nauru' },
	{ id: 'NP', label: 'Nepal' },
	{ id: 'NL', label: 'Netherlands' },
	{ id: 'AN', label: 'Netherlands Antilles' },
	{ id: 'NC', label: 'New Caledonia' },
	{ id: 'NZ', label: 'New Zealand' },
	{ id: 'NI', label: 'Nicaragua' },
	{ id: 'NE', label: 'Niger' },
	{ id: 'NG', label: 'Nigeria' },
	{ id: 'NU', label: 'Niue' },
	{ id: 'NF', label: 'Norfolk Island' },
	{ id: 'MP', label: 'Northern Mariana Islands' },
	{ id: 'NO', label: 'Norway' },
	{ id: 'OM', label: 'Oman' },
	{ id: 'PK', label: 'Pakistan' },
	{ id: 'PW', label: 'Palau' },
	{ id: 'PS', label: 'Palestinian Territory, Occupied' },
	{ id: 'PA', label: 'Panama' },
	{ id: 'PG', label: 'Papua New Guinea' },
	{ id: 'PY', label: 'Paraguay' },
	{ id: 'PE', label: 'Peru' },
	{ id: 'PH', label: 'Philippines' },
	{ id: 'PN', label: 'Pitcairn' },
	{ id: 'PL', label: 'Poland' },
	{ id: 'PT', label: 'Portugal' },
	{ id: 'PR', label: 'Puerto Rico' },
	{ id: 'QA', label: 'Qatar' },
	{ id: 'RE', label: 'Reunion' },
	{ id: 'RO', label: 'Romania' },
	{ id: 'RU', label: 'Russian Federation' },
	{ id: 'RW', label: 'Rwanda' },
	{ id: 'SH', label: 'Saint Helena' },
	{ id: 'KN', label: 'Saint Kitts And Nevis' },
	{ id: 'LC', label: 'Saint Lucia' },
	{ id: 'PM', label: 'Saint Pierre And Miquelon' },
	{ id: 'VC', label: 'Saint Vincent And The Grenadines' },
	{ id: 'WS', label: 'Samoa' },
	{ id: 'SM', label: 'San Marino' },
	{ id: 'ST', label: 'Sao Tome And Principe' },
	{ id: 'SA', label: 'Saudi Arabia' },
	{ id: 'SN', label: 'Senegal' },
	{ id: 'CS', label: 'Serbia And Montenegro' },
	{ id: 'SC', label: 'Seychelles' },
	{ id: 'SL', label: 'Sierra Leone' },
	{ id: 'SG', label: 'Singapore' },
	{ id: 'SK', label: 'Slovakia' },
	{ id: 'SI', label: 'Slovenia' },
	{ id: 'SB', label: 'Solomon Islands' },
	{ id: 'SO', label: 'Somalia' },
	{ id: 'ZA', label: 'South Africa' },
	{ id: 'GS', label: 'South Georgia and the South Sandwich Islands' },
	{ id: 'ES', label: 'Spain' },
	{ id: 'LK', label: 'Sri Lanka' },
	{ id: 'SD', label: 'Sudan' },
	{ id: 'SR', label: 'Suriname' },
	{ id: 'SJ', label: 'Svalbard And Jan Mayen Islands' },
	{ id: 'SZ', label: 'Swaziland' },
	{ id: 'SE', label: 'Sweden' },
	{ id: 'CH', label: 'Switzerland' },
	{ id: 'SY', label: 'Syrian Arab Republic' },
	{ id: 'TW', label: 'Taiwan' },
	{ id: 'TJ', label: 'Tajikistan' },
	{ id: 'TZ', label: 'United Republic of Tanzania' },
	{ id: 'TH', label: 'Thailand' },
	{ id: 'TL', label: 'Timor-Leste' },
	{ id: 'TG', label: 'Togo' },
	{ id: 'TK', label: 'Tokelau' },
	{ id: 'TO', label: 'Tonga' },
	{ id: 'TT', label: 'Trinidad and Tobago' },
	{ id: 'TN', label: 'Tunisia' },
	{ id: 'TR', label: 'Turkey' },
	{ id: 'TM', label: 'Turkmenistan' },
	{ id: 'TC', label: 'Turks And Caicos Islands' },
	{ id: 'TV', label: 'Tuvalu' },
	{ id: 'UG', label: 'Uganda' },
	{ id: 'UA', label: 'Ukraine' },
	{ id: 'AE', label: 'United Arab Emirates' },
	{ id: 'GB', label: 'United Kingdom' },
	{ id: 'US', label: 'United States' },
	{ id: 'UM', label: 'United States Minor Outlying Islands' },
	{ id: 'UY', label: 'Uruguay' },
	{ id: 'UZ', label: 'Uzbekistan' },
	{ id: 'VA', label: 'Vatican City State (Holy See)' },
	{ id: 'VE', label: 'Venezuela' },
	{ id: 'VN', label: 'Viet Nam' },
	{ id: 'VG', label: 'Virgin Islands (British)' },
	{ id: 'VI', label: 'Virgin Islands (US)' },
	{ id: 'WF', label: 'Wallis And Futuna Islands' },
	{ id: 'EH', label: 'Western Sahara' },
	{ id: 'YE', label: 'Yemen' },
	{ id: 'ZM', label: 'Zambia' },
	{ id: 'ZW', label: 'Zimbabwe' },
];
