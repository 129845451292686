import { API_CALL_FAILED, API_CALL_RUNNING, API_CALL_SUCCEEDED, CHANGE_API_CALL_TAB, CLOSE_TOAST_MESSAGE, HIDE_API_CALL, SHOW_API_CALL, SHOW_TOAST_MESSAGE, SET_REDIRECT_URL_AFTER_LOGIN, SET_AUTHENTICATION_ERROR } from './actions';

const initialState = {
	apiCall: {
		show: false,
		running: false,
		result: null,
		error: null,
		activeTab: 'description',
		payload: null,
	},
	toastMessage: {
		show: false,
		title: null,
		message: null,
	},
	redirectUrlAfterLogin: null,
	isAuthenticationError: false,
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case SHOW_API_CALL:
			return {
				...state,
				apiCall: {
					show: true,
					running: false,
					result: null,
					error: null,
					activeTab: 'description',
					...action.payload,
				},
			};
		case API_CALL_RUNNING:
			return {
				...state,
				apiCall: {
					...state.apiCall,
					running: action.payload,
				},
			};
		case API_CALL_SUCCEEDED:
			return {
				...state,
				apiCall: {
					...state.apiCall,
					result: action.payload,
					error: null,
				},
			};
		case API_CALL_FAILED:
			return {
				...state,
				apiCall: {
					...state.apiCall,
					result: null,
					error: action.payload,
				},
			};
		case CHANGE_API_CALL_TAB:
			return {
				...state,
				apiCall: {
					...state.apiCall,
					activeTab: action.payload,
				},
			};
		case HIDE_API_CALL:
			return {
				...state,
				apiCall: {
					...state.apiCall,
					show: false,
					title: null,
				},
			};
		// WALLET UI
		case SHOW_TOAST_MESSAGE:
			return {
				...state,
				toastMessage: {
					show: true,
					title: action.payload?.title,
					message: action.payload?.message,
				},
			};
		case CLOSE_TOAST_MESSAGE:
			return {
				...state,
				toastMessage: {
					show: false,
				},
			};
		case SET_REDIRECT_URL_AFTER_LOGIN:
			return {
				...state,
				redirectUrlAfterLogin: action.payload,
			};
		case SET_AUTHENTICATION_ERROR:
			return {
				...state,
				isAuthenticationError: action.payload,
			};
		default:
			return state;
	}
}

export default reducer;
