export const SHOW_API_CALL = 'SHOW_API_CALL';
export const showApiCall = ({ title, description, requestAnnotations, responseAnnotations, type, queryPath, parameters }) => ({
	type: SHOW_API_CALL,
	payload: {
		title,
		description,
		requestAnnotations,
		responseAnnotations,
		type,
		queryPath,
		parameters,
	},
});

export const API_CALL_RUNNING = 'API_CALL_RUNNING';
export const apiCallRunning = (running) => ({
	type: API_CALL_RUNNING,
	payload: running,
});

export const API_CALL_SUCCEEDED = 'API_CALL_SUCCEEDED';
export const apiCallSucceeded = (result) => ({
	type: API_CALL_SUCCEEDED,
	payload: result,
});

export const API_CALL_FAILED = 'API_CALL_FAILED';
export const apiCallFailed = (error) => ({
	type: API_CALL_SUCCEEDED,
	payload: error,
});

export const CHANGE_API_CALL_TAB = 'CHANGE_API_CALL_TAB';
export const changeApiCallTab = (tab) => ({
	type: CHANGE_API_CALL_TAB,
	payload: tab,
});

export const HIDE_API_CALL = 'HIDE_API_CALL';
export const hideApiCall = () => ({
	type: HIDE_API_CALL,
});

// WALLET UI

export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const showToastMessage = ({ title, message }) => {
	return {
		type: SHOW_TOAST_MESSAGE,
		payload: { title, message },
	};
};

export const CLOSE_TOAST_MESSAGE = 'CLOSE_TOAST_MESSAGE';
export const closeToastMessage = () => ({
	type: CLOSE_TOAST_MESSAGE,
});

export const SET_REDIRECT_URL_AFTER_LOGIN = 'SET_REDIRECT_URL_AFTER_LOGIN';
export const setRedirectUrlAfterLogin = (url) => ({
	type: SET_REDIRECT_URL_AFTER_LOGIN,
	payload: url,
});

export const SET_AUTHENTICATION_ERROR = 'SET_AUTHENTICATION_ERROR';
export const setAuthenticationError = (isAuthenticationError) => ({
	type: SET_AUTHENTICATION_ERROR,
	payload: isAuthenticationError,
});
