import { useCallback, useEffect, useState } from 'react';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { generatePath } from 'react-router';
import { formatAmount } from 'cw-demowallet-common/src/utils';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';

function LoyaltyMembership({ purchaseId, walletId, consumerIssuableLoyaltyCreditsAmount, onCreatedMembership }) {
	const dispatch = useDispatch();
	const [programId, setProgramId] = useState();
	const [walletType, setWalletType] = useState();
	const [hasOpted, setHasOpted] = useState(false);
	const { apiClient, apiClientWithDialog } = useApi();

	const loadWalletTypeAsync = useCallback(async () => {
		if (walletId) {
			const response = await apiClient({
				method: 'GET',
				url: generatePath(API_ENDPOINTS.WALLET_GET, { walletId }),
			});
			const walletType = response?.data?.walletType;
			setWalletType(walletType);
		}
	}, [walletId, apiClient]);

	const createMembershipAsync = async () => {
		try {
			await apiClientWithDialog({
				title: 'Create Loyalty Program Membership',
				description: '<p>The <strong>Create Loyalty Program Membership</strong> API is used to create loyalty program membership for the customer for the loyalty program of the shop.',
				requestAnnotations: {
					consumerWalletId: 'Wallet ID of customer',
					loyaltyProgramId: 'Active Loyalty Program for the shop.',
				},
				responseAnnotations: {
					id: 'The ID of the newly created loyalty membership.',
					creationDateTime: 'Date and time when the loyalty membership was created.',
					loyaltyProgramId: 'Loyalty Program ID for which loyalty membership was created',
					walletId: 'Customer Wallet ID',
					walletAccountId: 'Customer Wallet Account ID',
					creator: {
						type: 'Creator Type',
						id: 'Creator ID',
					},
				},
				type: 'POST',
				queryPath: '/demowallet/wallet/tx/loyalty/memberships',
				parameters: {
					consumerWalletId: walletId,
					loyaltyProgramId: programId,
				},
			});
			dispatch(
				showToastMessage({
					title: 'Loyalty Membership',
					message: 'You have successfully opted in for the loyalty program offered by the shop. You can use the loyalty credits for your purchases.',
				}),
			);
			await initComponentAsync();
			onCreatedMembership();
		} catch {
			dispatch(
				showToastMessage({
					title: 'Loyalty Membership',
					message: 'Failed to create loyalty membership for the loyalty program offered by the shop. Please contact DemoWallet support if the problem persist.',
				}),
			);
		}
	};

	const listMembershipsAsync = useCallback(
		async (programId) => {
			const response = await apiClient({
				method: 'GET',
				url: generatePath(API_ENDPOINTS.LOYALTY_MEMBERSHIPS, {
					customerWalletId: walletId,
					loyaltyProgramId: programId,
				}),
			});
			setHasOpted(response?.meta?.totalItems !== 0);
		},
		[walletId, apiClient],
	);

	const initComponentAsync = useCallback(async () => {
		setHasOpted(false);
		if (purchaseId) {
			const response = await apiClient({
				method: 'GET',
				url: API_ENDPOINTS.LOYALTY_PUBLIC_DATA,
				data: { purchaseId },
			});
			if (response?.data[0]) {
				const programId = response?.data[0].loyaltyProgramId;
				setProgramId(programId);
				await listMembershipsAsync(programId);
			}
			await loadWalletTypeAsync();
		}
	}, [listMembershipsAsync, loadWalletTypeAsync, purchaseId, apiClient]);

	useEffect(() => initComponentAsync(), [initComponentAsync]);

	return (
		<>
			{walletType === 'Full' && (
				<>
					{hasOpted && consumerIssuableLoyaltyCreditsAmount && (
						<p>
							You receive loyalty credits of&nbsp;
							<strong>{formatAmount(consumerIssuableLoyaltyCreditsAmount)}</strong>
							&nbsp;for this purchase.
						</p>
					)}
					{!hasOpted && (
						<p>
							This merchant offers a loyalty program, click&nbsp;
							<button className="text-link" onClick={createMembershipAsync}>
								here
							</button>
							&nbsp;to opt-in and receive credits for your purchase.
						</p>
					)}
				</>
			)}
		</>
	);
}

export default LoyaltyMembership;
