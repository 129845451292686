import { Button, Modal } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import Input from 'cw-demowallet-common/src/components/input/Input';
import './RevokeDocument.scss';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { generatePath } from 'react-router';
import React from 'react';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';

function RevokeDocument({ hideUploadModal, show, selectedDocument, updateDocumentAsync }) {
	const { apiClient } = useApi();
	const dispatch = useDispatch();

	const revokeDocumentAsync = async (formData) => {
		try {
			await apiClient({
				method: 'POST',
				data: { comment: formData.comment },
				url: generatePath(API_ENDPOINTS.REVOKE_USER_DOCUMENT, {
					documentId: selectedDocument.id,
				}),
			});
			dispatch(
				showToastMessage({
					title: 'Success',
					message: 'Your document ' + selectedDocument.originalFileName + ' of type ' + selectedDocument.qualifier + ' has been revoked successfully.',
				}),
			);
			await updateDocumentAsync();
			hideUploadModal();
		} catch {
			dispatch(
				showToastMessage({
					title: 'Unexpected exception',
					message: 'Error in revoking your document ' + selectedDocument.originalFileName + ' of type ' + selectedDocument.qualifier + '.',
				}),
			);
		}
	};

	return (
		<Modal show={show} className="upload-documents">
			<Modal.Header>Revoke document</Modal.Header>
			<Modal.Body>
				<Formik
					initialValues={{
						comment: '',
					}}
					onSubmit={revokeDocumentAsync}
				>
					<Form>
						<div>
							<div>
								<Input label="Comments for revoking:" name="comment" type="text" />
							</div>
							<div>
								<Button variant="primary" className="button-ops" type="submit">
									Revoke document
								</Button>
							</div>
							<hr />
							<div className="button-container">
								<Button variant="outline-dark" className="button-ops" type="button" onClick={hideUploadModal}>
									Close
								</Button>
							</div>
						</div>
					</Form>
				</Formik>
			</Modal.Body>
		</Modal>
	);
}

export default RevokeDocument;
