import React, { useEffect, useState } from 'react';
import './PurchaseSuccess.scss';

function PurchaseSuccess({ purchase, isMarketplace }) {
	const [returnUrl, setReturnUrl] = useState();

	useEffect(() => {
		if (isMarketplace) {
			setReturnUrl(purchase && purchase?.marketplaceRedirectUrl && purchase.marketplaceRedirectUrl.replace('{{purchaseId}}', purchase.id).replace('{{purchaseStatus}}', 'Success'));
		} else {
			setReturnUrl(purchase && purchase?.shopReturnUrl && purchase.shopReturnUrl.replace('{{purchaseId}}', purchase.id).replace('{{purchaseStatus}}', 'Success'));
		}
	}, [purchase, isMarketplace]);

	useEffect(() => {
		if (returnUrl) {
			setTimeout(() => {
				document.location.href = returnUrl;
			}, 5000);
		}
	}, [returnUrl]);

	return (
		<section className="purchase-success">
			<i className="fa fa-check big" aria-hidden="true"></i>
			<p>Your payment was successful.</p>
			{purchase && (
				<p>
					You will be redirected to the shop in 5 seconds.
					<br />
					Alternatively, click{' '}
					<a className="text-link" href={returnUrl}>
						here
					</a>{' '}
					to return to the shop.
				</p>
			)}
			{!purchase && (
				<p>
					Please wait... <i className="fa fa-spin fa-spinner"></i>
				</p>
			)}
		</section>
	);
}

export default PurchaseSuccess;
