import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Signup from './routes/signup/Signup';
import Login from './routes/login/Login';
import Purchase from './routes/purchase/Purchase';
import PasswordRecovery from './routes/passwordRecovery/PasswordRecovery';
import PasswordForgotten from './routes/passwordForgotten/PasswordForgotten';
import ServiceArea from './routes/serviceArea/ServiceArea';
import Home from './routes/home/Home';
import AjaxAnnotation from 'cw-demowallet-common/src/components/ajaxAnnotation/AjaxAnnotation';
import AppContext from 'cw-demowallet-common/src/AppContext';
import ToastMessage from 'cw-demowallet-common/src/components/toastMessage/ToastMessage';
import './App.scss';

export const APP_ROUTES = {
	HOME: '/',
	PURCHASE: '/purchase/:purchaseId',
	PURCHASE_MARKETPLACE: '/purchase/marketplace/:purchaseId',
	SIGNUP: '/signup',
	LOGIN: '/login',
	PASSWORD_RECOVERY: '/password-recovery-started',
	PASSWORD_FORGOTTEN: '/password-forgotten',
	SERVICE_AREA: '/servicearea',
};

function App() {
	return (
		<AppContext>
			<AjaxAnnotation />
			<Switch>
				<Route path={APP_ROUTES.HOME} exact component={Home} />
				<Route path={APP_ROUTES.PURCHASE_MARKETPLACE} component={(props) => <Purchase isMarketplace={true} {...props} />} />
				<Route path={APP_ROUTES.PURCHASE} component={(props) => <Purchase isMarketplace={false} {...props} />} />
				<Route path={APP_ROUTES.SIGNUP} component={Signup} />
				<Route path={APP_ROUTES.LOGIN} component={Login} />
				<Route path={APP_ROUTES.PASSWORD_RECOVERY} component={PasswordRecovery} />
				<Route path={APP_ROUTES.PASSWORD_FORGOTTEN} component={PasswordForgotten} />
				<Route path={APP_ROUTES.SERVICE_AREA} component={ServiceArea} />
			</Switch>
			<ToastMessage />
		</AppContext>
	);
}

export default App;

export const useQueryParams = () => new URLSearchParams(useLocation().search);

export const postDynamicForm = (url, formValues) => {
	const dynamicForm = document.createElement('form');
	dynamicForm.setAttribute('method', 'post');
	dynamicForm.setAttribute('action', url);
	for (let key in formValues) {
		const hiddenInput = document.createElement('input');
		hiddenInput.setAttribute('type', 'hidden');
		hiddenInput.setAttribute('name', key);
		hiddenInput.setAttribute('value', formValues[key]);
		dynamicForm.appendChild(hiddenInput);
	}
	document.body.appendChild(dynamicForm);
	dynamicForm.submit();
};
