export const SERVICE_AREA_ROUTES = {
	HOME: '/servicearea',
	TOP_UP: '/servicearea/topup',
	WITHDRAW: '/servicearea/withdraw',
	P2P: '/servicearea/p2p',
	REDEEM: '/servicearea/redeem/:transactionId',
	VOUCHER: '/servicearea/voucher',
	VERIFY_EMAIL: '/servicearea/verify-email',
	PROFILE: '/servicearea/profile',
	INVOICE_LIST: '/servicearea/invoices',
	INVOICE: '/servicearea/invoice/:invoiceId',
	SHOP_CONFIG_EDITOR: '/servicearea/shop-configurations/edit/:shopConfigurationId',
	SHOP_CONFIG: '/servicearea/shop-configurations',
	SHOP_CONFIG_ADD: '/servicearea/shop-configurations/add',
	BALANCES: '/servicearea/balances',
	PURCHASES: '/servicearea/purchases',
	PURCHASE_VIEW: '/servicearea/purchase/:purchaseId',
	WALLET_TRANSACTION: '/servicearea/wallet-transactions',
	SETTLEMENT_SUMMARY_LIST: '/servicearea/billing-and-settlement-summary-list',
};
