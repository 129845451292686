import React, { useEffect, useRef, useState } from 'react';
import './AnnotatedJson.scss';

function AnnotatedJson({ value = {}, annotations }) {
	const [jsonHtml, setJsonHtml] = useState('');
	const divRef = useRef();

	useEffect(() => {
		let json = JSON.stringify(value, undefined, 2);
		json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
		json = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
			var cls = 'number';
			if (/^"/.test(match)) {
				if (/:$/.test(match)) {
					cls = 'key';
				} else {
					cls = 'string';
				}
			} else if (/true|false/.test(match)) {
				cls = 'boolean';
			} else if (/null/.test(match)) {
				cls = 'null';
			}
			return '<span class="' + cls + '">' + match + '</span>';
		});
		if (annotations) {
			const keys = Object.keys(annotations);
			for (let i = 0; i < keys.length; ++i) {
				json = json.replace(`"${keys[i]}":`, `"<span class="has-details" data-content="${annotations[keys[i]]}">${keys[i]}</span>":`);
			}
		}
		setJsonHtml(json);
	}, [value, annotations]);

	useEffect(() => {
		if (divRef.current) {
			const help = divRef.current.querySelectorAll('p.help')[0];
			divRef.current.querySelectorAll('.has-details').forEach((node) => {
				node.addEventListener(
					'mouseenter',
					function (event) {
						help.innerText = node.dataset.content;
					},
					false,
				);
			});
		}
	}, [jsonHtml]);

	return (
		<div className="annotated-json" ref={divRef}>
			<pre dangerouslySetInnerHTML={{ __html: jsonHtml }} />
			<p>
				Hover an <span className="underlined">underlined</span> attribute for more details:
			</p>
			<p className="help"></p>
		</div>
	);
}

export default AnnotatedJson;
