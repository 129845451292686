import React, { forwardRef, useImperativeHandle } from 'react';
import { PAYMENT_OPTION_CONSTANTS } from '../PaymentOptionConstants';

const DemoPaypalOption = forwardRef(({ isActive, triggerPayment, paymentMethod }, ref) => {
	useImperativeHandle(ref, () => ({
		startPayment: () => {
			triggerPayment({
				selectedPaymentMethod: PAYMENT_OPTION_CONSTANTS.DEMO_PAYPAL,
				selectedPaymentInstrumentId: paymentMethod.paymentInstruments[0].publicId,
			});
		},
	}));
	return (
		<div className="demo-paypal-option">
			<p>
				You can use <strong>PayPal 2.0</strong> to settle the payment amount either with your PayPal balance or by charging a payment instrument stored in your Paypal account.
			</p>
		</div>
	);
});

export default DemoPaypalOption;
