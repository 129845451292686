import { Button, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { APP_ROUTES, useQueryParams } from '../../App';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import Input from 'cw-demowallet-common/src/components/input/Input';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { PURCHASE_ROUTES } from '../purchase/Purchase';
import { SERVICE_AREA_ROUTES } from 'cw-demowallet-common/src/serviceAreaRoutes';
import { generatePath } from 'react-router';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import './Login.scss';
import { useSelector } from 'react-redux';
import { setRedirectUrlAfterLogin, showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';

const loginSchema = Yup.object().shape({
	email: Yup.string().required('Please enter your email address.').max(255, 'Please enter a shorter email address.').email('Please enter a valid email address.'),
	password: Yup.string().required('Please enter your password.').min(8, 'Password is too short - should be 8 chars minimum.'),
});

function Login() {
	const reactHistory = useHistory();
	const queryParams = useQueryParams();
	const [email, setEmail] = useState(null);
	const [, setCookie] = useCookies();
	const { apiClient, apiClientWithDialog, setAuthentication } = useApi();
	const redirectUrlAfterLogin = useSelector((state) => state.redirectUrlAfterLogin);
	const isAuthenticationError = useSelector((state) => state.isAuthenticationError);
	const dispatch = useDispatch();

	useEffect(() => {
		if (isAuthenticationError) {
			dispatch(showToastMessage({ title: 'Authentication Error', message: 'Please login again.' }));
		}
	}, [isAuthenticationError, dispatch]);

	const handleLoginAsync = async (formData, { setErrors }) => {
		setAuthentication(null);
		try {
			const response = await apiClientWithDialog({
				withCredentials: false,
				title: 'Login',
				description: '<p>The consumer chose to <strong>login as a registered User</strong>, using the email address.</p><p>CoreWallet also allows configuring other identifiers for login, like the mobile phone number.</p>',
				requestAnnotations: {
					userIdentifier: "The registered user's e-mail address.",
					password: "The registered user's password.",
				},
				responseAnnotations: {
					walletId: 'This is the wallet, associated to the registered user.',
					subsidiaryId: 'This is the subsidiary to which the wallet belongs.',
					error: 'An error has occurred, the flow cannot proceed and the UI will attempt to handle the error accordingly.',
					code: 'This is the error code the UI will respond to.',
				},
				type: 'POST',
				queryPath: API_ENDPOINTS.AUTHENTICATION_LOGIN,
				parameters: {
					country: 'DE',
					userIdentifier: formData.email,
					password: formData.password,
				},
			});
			loginSucceeded(response?.data, setErrors);
			deleteRedirectUrl();
		} catch (error) {
			loginFailed(error, setErrors);
		}
	};

	const loginFailed = (error, setErrors) => {
		setErrors({
			email: 'E-mail or Password wrong ...',
			password: 'E-mail or Password wrong ...',
		});
	};

	const loginSucceeded = (loginData, setErrors) => {
		if (loginData?.roles?.includes('ADMIN')) {
			setErrors({ email: 'This login is not meant for an admin user!' });
			return;
		}
		if (loginData?.walletId && loginData?.apiKey) {
			setAuthentication({
				apiKey: loginData.apiKey,
				walletId: loginData.walletId,
			});

			const purchaseId = queryParams.get('purchaseId');
			const isMarketplace = queryParams.get('isMarketplace');

			if (purchaseId) {
				reactHistory.push(generatePath(isMarketplace ? PURCHASE_ROUTES.OPTIONS_MARKETPLACE : PURCHASE_ROUTES.OPTIONS, { purchaseId }));
			} else if (redirectUrlAfterLogin !== null && redirectUrlAfterLogin !== undefined) {
				reactHistory.push(redirectUrlAfterLogin);
			} else {
				reactHistory.push(SERVICE_AREA_ROUTES.HOME);
			}
		}
	};

	const deleteRedirectUrl = () => {
		if (redirectUrlAfterLogin !== null && redirectUrlAfterLogin !== undefined) {
			dispatch(setRedirectUrlAfterLogin(null));
		}
	};

	const recoverForgottenPasswordAsync = async (setFieldTouched) => {
		if (!email) {
			setFieldTouched('email', true);
			return;
		}
		try {
			const response = await apiClient({
				method: 'POST',
				data: { userIdentifier: email },
				url: generatePath(API_ENDPOINTS.PASSWORD_RECOVERY),
			});
			if (response.data.status === 'VALID' && moment().isBefore(moment(response.data.validUntil))) {
				setCookie('email', email);
				reactHistory.push(`${APP_ROUTES.PASSWORD_RECOVERY}?status=Success`);
			} else {
				reactHistory.push(`${APP_ROUTES.PASSWORD_RECOVERY}?status=Failed`);
			}
		} catch {
			reactHistory.push(`${APP_ROUTES.PASSWORD_RECOVERY}?status=Failed`);
		}
	};

	const formChangeEvent = (event) => {
		if (event.target.name === 'email') {
			setEmail(event.target.value);
		}
	};

	return (
		<section className="login">
			<Card>
				<Card.Body>
					<h3>Login with your DemoWallet account</h3>
					<Formik validationSchema={loginSchema} initialValues={{ email: '', password: '' }} onSubmit={handleLoginAsync}>
						{/*setFieldTouched is a property in formik props that helps to touch/activate the selected/current field validation*/}
						{({ setFieldTouched }) => (
							<Form onChange={formChangeEvent}>
								<Input label="E-Mail Address" name="email" type="email" id="email" placeholder="Your e-mail address..." />
								<Input label="Password" name="password" type="password" id="password" placeholder="Your password..." />
								<div className="text-center">
									<Button variant="primary" type="submit">
										Log-in now
									</Button>
									<p>
										...or <Link to={APP_ROUTES.SIGNUP}>sign-up</Link>
									</p>
									<p>
										<button className="text-link" onClick={() => recoverForgottenPasswordAsync(setFieldTouched)}>
											Did you forget your password?
										</button>
									</p>
								</div>
							</Form>
						)}
					</Formik>
				</Card.Body>
			</Card>
		</section>
	);
}

export default Login;
