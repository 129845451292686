import { Card, FormControl, FormGroup } from 'react-bootstrap';
import './AccountStatement.scss';
import { useCallback, useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { SERVICE_AREA_ROUTES } from '../../serviceAreaRoutes';
import { Link } from 'react-router-dom';
import AccountStatementManual from './accountStatmentRow/AccountStatmentManual';
import AccountStatementInvoice from './accountStatmentRow/AccountStatmentInvoice';
import AccountStatementPayment from './accountStatmentRow/AccountStatmentPayment';
import AccountStatementPurchase from './accountStatmentRow/AccountStatmentPurchase';
import AccountStatementP2PIncoming from './accountStatmentRow/AccountStatmentP2PIncoming';
import AccountStatementP2POutgoing from './accountStatmentRow/AccountStatmentP2POutgoing';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { showToastMessage } from 'cw-demowallet-common/src/redux/actions';
import { useDispatch } from 'react-redux';
import { API_ENDPOINTS } from '../../endpoints';

function AccountStatement({ wallet }) {
	const [accountItems, setAccountItems] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const { apiClient } = useApi();

	const accountSelectedAsync = async (change) => await loadSelectedAccountItemsAsync(wallet.accounts[change.target.value]);

	const loadSelectedAccountItemsAsync = useCallback(
		async (account) => {
			setIsLoading(true);
			try {
				const itemsResponse = await apiClient({
					method: 'GET',
					url: generatePath(API_ENDPOINTS.ACCOUNT_ITEMS_GET, { accountId: account?.walletAccountId }),
				});
				setAccountItems(itemsResponse?.data);
			} catch {
				dispatch(
					showToastMessage({
						title: 'Unexpected exception',
						message: 'An exception was raised while calling the Service: Get Account Item',
					}),
				);
			} finally {
				setIsLoading(false);
			}
		},
		[dispatch, apiClient],
	);

	useEffect(() => {
		loadSelectedAccountItemsAsync(wallet.accounts[0]);
	}, [wallet, loadSelectedAccountItemsAsync]);

	return (
		<Card className="account-statement">
			<Card.Body>
				<h3>
					Account Statement
					<FormGroup className="pull-right">
						<FormControl as="select" onChange={accountSelectedAsync}>
							{wallet.accounts.map((account, index) => (
								<option key={index} value={index}>
									{account.currency}&nbsp;({account.accountType})
								</option>
							))}
						</FormControl>
					</FormGroup>
				</h3>
				{isLoading && (
					<div className="progress-spinner">
						<i className="fa fa-spin fa-spinner"></i>
					</div>
				)}
				{!isLoading && (
					<>
						{(!accountItems || !accountItems?.length) && (
							<div className="empty-slate">
								Nothing to show yet. You can <Link to={SERVICE_AREA_ROUTES.TOP_UP}>top up your account</Link> or <a href="https://demowallet.trimplement.com/shop">shop at one of our merchants</a>.
							</div>
						)}
						{accountItems && accountItems?.length > 0 && (
							<table className="table table-hover">
								<thead>
									<tr>
										<th width="20%">Date</th>
										<th width="10%">Amount</th>
										<th width="10%">Status</th>
										<th>Description</th>
										<th width="1px"></th>
									</tr>
								</thead>
								<tbody>
									{accountItems.map((accountItem, index) => {
										const { itemType, itemSubtype } = accountItem;
										return (
											<tr key={index}>
												{itemType === 'ManualPayment' && <AccountStatementManual accountItem={accountItem} />}
												{itemType === 'Invoice' && <AccountStatementInvoice accountItem={accountItem} />}
												{itemType === 'Payment' && <AccountStatementPayment accountItem={accountItem} />}
												{itemType === 'Purchase' && <AccountStatementPurchase accountItem={accountItem} />}
												{itemType === 'P2P' && itemSubtype === 'Incoming' && <AccountStatementP2PIncoming accountItem={accountItem} />}
												{itemType === 'P2P' && itemSubtype === 'Outgoing' && <AccountStatementP2POutgoing accountItem={accountItem} />}
											</tr>
										);
									})}
								</tbody>
							</table>
						)}
					</>
				)}
			</Card.Body>
		</Card>
	);
}

export default AccountStatement;
