import React, { forwardRef, useImperativeHandle } from 'react';
import { Image } from 'react-bootstrap';
import './SepaOption.scss';
import { PAYMENT_OPTION_CONSTANTS } from '../PaymentOptionConstants';

const SepaOption = forwardRef(({ isActive, triggerPayment, paymentMethod }, ref) => {
	useImperativeHandle(ref, () => ({
		startPayment: () => {
			triggerPayment({
				selectedPaymentMethod: PAYMENT_OPTION_CONSTANTS.PAYPAL,
				selectedPaymentInstrumentId: paymentMethod.paymentInstruments[0].publicId,
			});
		},
	}));

	return (
		<div className="sepa-option">
			<p>
				<Image alt="SEPA logo" title="PayPal" className="logo" src={`${process.env.PUBLIC_URL}/assets/images/pm_sepa.png`} />
			</p>
			<p>
				We can debit the payment amount from your bank account using a <strong>SEPA direct debit</strong>.
			</p>
		</div>
	);
});

export default SepaOption;
