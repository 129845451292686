import { Form, Formik } from 'formik';
import { Button, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import Input from 'cw-demowallet-common/src/components/input/Input';
import './ServiceAreaP2P.scss';
import React, { useEffect, useState } from 'react';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';
import { formatAmount } from 'cw-demowallet-common/src/utils';
import { APP_ROUTES } from '../../../App';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { SERVICE_AREA_ROUTES } from 'cw-demowallet-common/src/serviceAreaRoutes';

function ServiceAreaP2P({ user, wallet }) {
	const reactHistory = useHistory();
	const [selectedAccount, setSelectedAccount] = useState();
	const [isDisabled, setIsDisabled] = useState(false);
	const { apiClientWithDialog } = useApi();

	const P2pSchema = Yup.object().shape({
		email: Yup.string().required('Please enter your email address.').max(255, 'Please enter a shorter email address.').email('Please enter a valid email address.'),
	});
	const handleStartP2PAsync = async (formData) => {
		setIsDisabled(true);
		try {
			await apiClientWithDialog({
				title: 'Create P2P Transaction',
				description:
					"<p>This call creates a new <strong>confirmed P2P transaction</strong>, i.e. there's no additional confirmation step necessary by the sender.</p>" +
					'<p>The recipient is identified by the given email address and will be notified through email. If the recipient is not yet a DemoWallet user, ' +
					'the funds can be redeemed after signing up.</p>',
				requestAnnotations: {
					amount: 'The amount to be sent.',
					currency: 'The currency of the amount.',
					description: 'A user-provided description of the transaction.',
					sourceEmail: "The sender's email address.",
					targetEmail: "The recipient's email address.",
				},
				responseAnnotations: {
					id: 'This unique ID of the P2P transaction has to be used for subsequent API calls, e.g. to cancel or redeem it.',
					sourceWalletId: "The sender's wallet ID.",
					targetWalletId: "The recipient's wallet ID, or <code>null</code> if the recipient is not a DemoWallet user yet.",
					amount: 'The amount of the P2P transaction.',
					error: 'An error has occurred, the flow cannot proceed and the UI will attempt to handle the error accordingly.',
					code: 'This is the error code the UI will respond to.',
				},
				type: 'POST',
				queryPath: API_ENDPOINTS.P2P_TRANSFER,
				parameters: {
					amount: formData.amount,
					currency: selectedAccount.currency,
					description: formData.description,
					sourceEmail: user.primaryEmail,
					targetEmail: formData.email,
				},
			});
			reactHistory.push(APP_ROUTES.SERVICE_AREA);
		} catch {
			setIsDisabled(false);
		}
	};

	const selectAccount = (change) => setSelectedAccount(wallet.accounts[change.target.value]);
	useEffect(() => setSelectedAccount(wallet.accounts[0]), [wallet]);

	const isInsufficientBalance = selectedAccount?.accountBalance?.balance?.amount <= 0;
	return (
		<div className="p2p">
			<h2>Transfer funds from your account balance</h2>
			<p className="help-text">Please specify the recipient's email address and the amount you would like to send.</p>
			<Formik
				validationSchema={P2pSchema}
				onSubmit={handleStartP2PAsync}
				initialValues={{
					amount: '',
					email: '',
					description: '',
				}}
			>
				<Form>
					{selectedAccount && (
						<p>
							Your available balance in {selectedAccount.currency} is {formatAmount(selectedAccount.accountBalance.balance)}
							{isInsufficientBalance && (
								<>
									Click <Link to={SERVICE_AREA_ROUTES.TOP_UP}>here</Link> to top up your balance
								</>
							)}
						</p>
					)}
					<div className="p2p-form">
						<div>
							<FormLabel>
								<strong>Amount</strong>
							</FormLabel>
							<Input className="amount-input" type="number" name="amount" disabled={isDisabled} />
						</div>
						<div>
							<FormGroup>
								<FormLabel>
									<strong>Currency</strong>
								</FormLabel>
								<FormControl as="select" disabled={isDisabled} onChange={selectAccount}>
									{wallet?.accounts.map((account, index) => (
										<option key={index} value={index}>
											{account.currency}
										</option>
									))}
								</FormControl>
							</FormGroup>
						</div>
					</div>
					<div>
						<FormLabel>
							<strong>Recipient's email</strong>
						</FormLabel>
						<Input className="text-input" type="text" name="email" disabled={isDisabled} />
					</div>
					<div>
						<FormLabel>
							<strong>Description (optional)</strong>
						</FormLabel>
						<Input className="text-input" type="text" name="description" disabled={isDisabled} />
					</div>
					<div>
						<Button variant="primary" className="btn-sm" type="submit" disabled={isDisabled}>
							Send
						</Button>
					</div>
				</Form>
			</Formik>
		</div>
	);
}

export default ServiceAreaP2P;
