import { Button, Card } from 'react-bootstrap';
import React from 'react';
import './ProfilePassword.scss';
import { Form, Formik } from 'formik';
import Input from 'cw-demowallet-common/src/components/input/Input';
import * as Yup from 'yup';
import { useApi } from 'cw-demowallet-common/src/apiClient';
import { API_ENDPOINTS } from 'cw-demowallet-common/src/endpoints';

function ProfilePassword({ user }) {
	const { apiClientWithDialog } = useApi();

	const handlePasswordUpdateAsync = async (formData) => {
		await apiClientWithDialog({
			title: 'Change password',
			description: '<p>The <strong>Change password</strong> API is used for changing the password for an existing user.',
			requestAnnotations: {
				country: 'Country of the user.',
				newPassword: 'New password of the user.',
				oldPassword: 'Old password of the user.',
				userIdentifier: 'Email address of the user.',
			},
			responseAnnotations: {},
			type: 'POST',
			queryPath: API_ENDPOINTS.CHANGE_PASSWORD,
			parameters: {
				country: user.citizenship,
				oldPassword: formData.oldPassword,
				newPassword: formData.newPassword,
				userIdentifier: user.displayPrimaryEmail,
			},
		});
	};
	return (
		<div className="profile-password">
			<Card>
				<Card.Header>Password Management</Card.Header>
				<Card.Body>
					<Formik
						validationSchema={updatePasswordValidation}
						initialValues={{
							oldPassword: '',
							newPassword: '',
							repeatNewPassword: '',
						}}
						onSubmit={handlePasswordUpdateAsync}
					>
						<Form>
							<Input label="Old password" name="oldPassword" type="password" placeholder="Old Password..." />
							<Input label="New Password" name="newPassword" type="password" placeholder="New Password" />
							<Input label="Repeat New Password" name="repeatNewPassword" type="password" placeholder="Repeat new Password" />
							<div className="button-container">
								<Button variant="primary" className="button-ops" type="submit">
									Change Password
								</Button>
							</div>
						</Form>
					</Formik>
				</Card.Body>
			</Card>
		</div>
	);
}

const updatePasswordValidation = Yup.object().shape({
	oldPassword: Yup.string().min(8, 'Please enter at least 8 characters').required('Please choose your old password.'),
	newPassword: Yup.string().required('Please enter your new password.').min(8, 'Please enter at least 8 characters'),
	repeatNewPassword: Yup.string()
		.required('Please enter your repeat new password.')
		.oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
});

export default ProfilePassword;
